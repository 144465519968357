import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import api from '~/services/api';

import { Content, DestaquesList, Destaque, NotFound } from './styles';

export default function Destaques({ search }) {
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    api
      .get('users/if-have-service', {
        params: {
          page: 1,
          search,
        },
      })
      .then(response => {
        const data = response.data.map(provider => ({
          id: provider.id,
          name: provider.name,
          slug: provider.slug,
          category: provider.category && provider.category.name,
          avatar: provider.avatar.url,
        }));

        setProviders(data);
      });
  }, [search]);
  return (
    <Content>
      <h2>Destaques</h2>
      <DestaquesList>
        {providers.length > 0 ? (
          <>
            {providers.map(provider => (
              <Destaque key={provider.id}>
                <Link to={`/perfil/${provider.slug}`}>
                  <img src={provider.avatar} alt={provider.name} />
                  <div>
                    <h3>{provider.name}</h3>
                    <p>{provider.category}</p>
                  </div>
                </Link>
              </Destaque>
            ))}
          </>
        ) : (
          <NotFound>
            <p>Nenhum prestador encontrado para esse filtro.</p>
          </NotFound>
        )}
      </DestaquesList>
    </Content>
  );
}

Destaques.propTypes = {
  search: PropTypes.string,
};

Destaques.defaultProps = {
  search: '',
};
