import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import api from '~/services/api';

import { Content, Servicos, Servico, Img, NotFound } from './styles';

export default function ServicosList({ search, category }) {
  const [servicos, setServicos] = useState([]);

  useEffect(() => {
    api
      .get('/services', {
        params: {
          category,
          page: 1,
          search,
        },
      })
      .then(response => {
        const data = response.data.map(service => ({
          id: service.id,
          name: service.name,
          description: service.description,
          image: service.files[0].url,
          slug: service.slug,
        }));
        setServicos(data);
      });
  }, [category, search]);

  return (
    <Content>
      <Servicos>
        {servicos.length > 0 ? (
          <>
            {servicos.map(service => (
              <Servico key={service.id}>
                <div>
                  <Img src={service.image} />
                  <div>
                    <h3>{service.name}</h3>
                    <p>{service.description}</p>
                    <Link to={`/servico/${service.slug}`}>Visualizar</Link>
                  </div>
                </div>
              </Servico>
            ))}
          </>
        ) : (
          <NotFound>
            <p>Nenhum serviço encontrado para esse filtro.</p>
          </NotFound>
        )}
      </Servicos>
    </Content>
  );
}

ServicosList.propTypes = {
  search: PropTypes.string,
  category: PropTypes.string,
};

ServicosList.defaultProps = {
  search: '',
  category: '',
};
