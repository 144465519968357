export function signInRequest(email, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  };
}

export function signInSuccess(user, token) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { user, token },
  };
}

export function signUpRequest(
  avatar,
  name,
  email,
  password,
  person_type,
  birthday,
  sex,
  rg,
  document,
  company_name,
  celphone,
  telphone,
  cep,
  street,
  number,
  neighborhood,
  city,
  state,
  complement,
  id_category
) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: {
      avatar,
      name,
      email,
      password,
      person_type,
      birthday,
      sex,
      rg,
      document,
      company_name,
      celphone,
      telphone,
      street,
      number,
      neighborhood,
      city,
      state,
      cep,
      complement,
      id_category,
    },
  };
}

export function updateRequest(
  id,
  avatar,
  name,
  email,
  person_type,
  birthday,
  sex,
  rg,
  document,
  company_name,
  celphone,
  telphone,
  id_category
) {
  return {
    type: '@auth/UPDATE_REQUEST',
    payload: {
      id,
      avatar,
      name,
      email,
      person_type,
      birthday,
      sex,
      rg,
      document,
      company_name,
      celphone,
      telphone,
      id_category,
    },
  };
}

export function updateSuccess(user) {
  return {
    type: '@auth/UPDATE_SUCCESS',
    payload: { user },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}
