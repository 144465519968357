import styled from 'styled-components';

export const Content = styled.div`
  h1 {
    font-size: 4rem;
    color: #173e4f;
    font-weight: 500;
    margin-bottom: 40px;
  }
`;

export const Options = styled.div`
  ul {
    display: flex;
    border-bottom: 1px solid #e1e3e8;

    li {
      margin-bottom: -2px;
      border-bottom: 3px solid transparent;
      transition-duration: 0.3s;

      button {
        background: none;
        border: none;
        padding: 0 10px;
        font-size: 2rem;
        color: #c5ccd6;
        text-align: left;
        font-weight: 500;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: transparent;
        transition-duration: 0.3s;
      }
      :hover {
        border-color: #286d8a;

        button {
          color: #286d8a;
          -webkit-text-stroke-color: #286d8a;
        }
      }

      + li {
        margin-left: 40px;
      }
    }

    li.active {
      border-color: #286d8a;

      button {
        color: #286d8a;
        -webkit-text-stroke-color: #286d8a;
      }
    }
  }

  @media screen and (max-width: 991px) {
    ul {
      justify-content: space-around;

      li {
        button {
          font-size: 1.8rem;
          text-align: center;
        }
        + li {
          margin-left: 0px;
        }
      }
    }
  }
`;

export const Option = styled.div``;
