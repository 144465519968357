import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { Form as FormComponent } from '@unform/web';

export const Content = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    label.changeAvatar {
      display: flex;
      margin: 0 auto 25px;
      text-align: center;
      width: 150px;
      border: none;
      background: none;
      transition-duration: 0.3s;

      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background: #ccc;
        display: block;
      }

      input {
        display: none;
      }

      :hover {
        opacity: 0.7;
      }
    }

    a {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #173e4f;
      border: 1px solid #173e4f;
      border-radius: 50px;
      height: 30px;
      margin-bottom: 10px;
      transition-duration: 0.3s;

      :hover {
        color: ${lighten(0.1, '#173E4F')};
        border-color: ${lighten(0.1, '#173E4F')};
      }
    }

    button.changePass {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #173e4f;
      border: 1px solid #173e4f;
      color: #fff;
      margin-bottom: 20px;
      border-radius: 50px;
      height: 30px;
      transition-duration: 0.3s;

      :hover {
        border-color: ${lighten(0.1, '#173E4F')};
        background-color: ${lighten(0.1, '#173E4F')};
      }
    }

    button.logout {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f3534a;
      border: 1px solid #f3534a;
      color: #fff;
      border-radius: 50px;
      height: 30px;
      transition-duration: 0.3s;

      :hover {
        border-color: ${darken(0.1, '#F3534A')};
        background-color: ${darken(0.1, '#F3534A')};
      }
    }
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    margin-top: 20px;

    div {
      a,
      button {
        margin-bottom: 5px !important;
      }
    }
  }
`;

export const Title = styled.h2`
  color: #1d2433;
  font-size: 5rem;
  font-weight: 500;
  margin-bottom: -30px;

  @media screen and (max-width: 991px) {
    font-size: 3rem;
    text-align: center;
  }
`;

export const Form = styled(FormComponent)`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
`;

export const InputGroup = styled.div`
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;

  label {
    font-size: 1.5rem;
    color: #c5ccd6;
  }

  > div {
    padding: 10px;
    width: 100%;

    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid #d8d8d8;
      height: 40px;
      font-size: 1.5rem;
      color: #323643;
      padding: 0 10px;
    }

    input.active {
      border-bottom: 1px solid #323643;
    }

    div {
      display: flex;
      align-items: center;

      label {
        padding: 0 5px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    > div {
      input {
        height: 20px;
      }
    }
  }
`;

export const ButtonModal = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
  top: 20px;

  button {
    border: none;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #173e4f;
    color: #fff;
    border-radius: 50px;
    height: 30px;
    margin-bottom: 15px;
    transition-duration: 0.3s;

    :hover {
      background-color: ${lighten(0.1, '#173E4F')};
    }
  }
`;
