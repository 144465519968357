import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Form as FormComponent } from '@unform/web';

import api from '~/services/api';

import { updateRequest, signOut } from '~/store/modules/auth/actions';

import { Content, Title, Form, InputGroup, ButtonModal } from './styles';
import Input from '~/components/Input';
import InputFile from '~/components/InputFile';
import Modal from '~/components/Modal';

function AvatarGroup() {
  const dispatch = useDispatch();
  const id = useSelector(state => state.auth.user.id);
  const [opened, setOpened] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [user, setUser] = useState({});

  useEffect(() => {
    api.get(`/user/${id}`).then(response => {
      setUser(response.data);
      setAvatarUrl(response.data.avatar.url);
    });
  }, [id]);

  const handleChangeAvatar = useCallback(
    avatarFile => {
      setAvatarUrl(URL.createObjectURL(avatarFile));
      const fileData = new FormData();
      fileData.append('file', avatarFile);

      dispatch(
        updateRequest(
          user.id,
          fileData,
          user.name,
          user.email,
          user.person_type,
          user.birthday,
          user.sex,
          user.rg,
          user.document,
          user.company_name,
          user.celphone,
          user.telphone,
          user.id_category
        )
      );
    },
    [dispatch, user]
  );

  const handleSavePassword = useCallback(
    async ({ password, password_confirmation }) => {
      if (password === password_confirmation) {
        const response = await api.put(`users/${id}`, {
          password,
        });

        if (response.status === 200) {
          Swal.fire(
            'Senha atualizada!',
            'Sua senha foi alterada com sucesso.',
            'success'
          ).then(_ => {
            setOpened(false);
          });
        }
      }
    },
    [id]
  );

  return (
    <>
      <Content>
        <div>
          <label htmlFor="avatar" type="button" className="changeAvatar">
            <FormComponent>
              <InputFile
                hangleImageChange={handleChangeAvatar}
                avatarPreview={avatarUrl || ''}
              />
            </FormComponent>
          </label>
          <Link to="/perfil/servico/cadastrar">
            <p>Cadastrar Serviço</p>
            <p>+</p>
          </Link>
          <button
            type="button"
            className="changePass modalButton"
            onClick={() => setOpened(true)}
          >
            Trocar de Senha
          </button>
          <button
            type="button"
            className="logout"
            onClick={() => dispatch(signOut())}
          >
            Sair
          </button>
        </div>
      </Content>
      <Modal opened={opened} close={() => setOpened(false)}>
        <Title>Trocar a Senha</Title>
        <Form onSubmit={handleSavePassword}>
          <InputGroup>
            <div>
              <label htmlFor="password">Nova Senha</label>
              <Input type="password" name="password" />
            </div>
          </InputGroup>
          <InputGroup>
            <div>
              <label htmlFor="password_confirmation">Confirmar Senha</label>
              <Input type="password" name="password_confirmation" />
            </div>
          </InputGroup>
          <ButtonModal>
            <button type="submit">Trocar senha</button>
          </ButtonModal>
        </Form>
      </Modal>
    </>
  );
}

export default AvatarGroup;
