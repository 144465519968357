import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';
import {
  FaBriefcase,
  FaCommentDots,
  FaUserAlt,
  FaHistory,
} from 'react-icons/fa';

import Logo from '~/assets/logos/logo-completo.svg';

import { BgHeader, Content } from './styles';

export default function Header() {
  const location = useLocation();
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(location.pathname.substring(1));
  }, [location.pathname]);

  return (
    <BgHeader>
      <Content>
        <div>
          <img src={Logo} alt="Logo do Wodo" />
          <ul>
            <li className={`${url === '' ? 'active' : ''}`}>
              <Link to="/">
                <FiHome size={20} color="#9e9c9c" />
                Home
              </Link>
            </li>
            <li
              className={`${
                url.match(/servico.*/) && !url.match(/perfil.*/) ? 'active' : ''
              }`}
            >
              <Link to="/servicos">
                <FaBriefcase size={20} color="#9e9c9c" />
                Serviços
              </Link>
            </li>
            <li className={`${url.match(/chat.*/) ? 'active' : ''}`}>
              <Link to="/chat">
                <FaCommentDots size={20} color="#9e9c9c" />
                Chat
              </Link>
            </li>
            <li className={`${url === 'historico' ? 'active' : ''}`}>
              <Link to="/historico">
                <FaHistory size={20} color="#9e9c9c" />
                Histórico
              </Link>
            </li>
            <li className={`${url.match(/perfil.*/) ? 'active' : ''}`}>
              <Link to="/perfil/meus-dados">
                <FaUserAlt size={20} color="#9e9c9c" />
                Perfil
              </Link>
            </li>
          </ul>
        </div>
      </Content>
    </BgHeader>
  );
}
