import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #adcdf85c;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition-duration: 0.1s;
  opacity: ${props => (props.opened ? '1' : '0')};
  visibility: ${props => (props.opened ? 'visible' : 'hidden')};
`;

export const Content = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000026;
  border-radius: 10px;
  padding: 30px 50px;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 991px) {
    padding: 20px 10px;
    margin: 5px;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;

  svg {
    color: #ffca18;
    width: 30px;
    height: 30px;
    transition-duration: 0.3s;
  }

  :hover {
    svg {
      color: ${shade(0.1, '#ffca18')};
    }
  }
`;
