import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import api from '~/services/api';

import { Content, CategoriasList, Categoria, NotFound } from './styles';

export default function Categorias({ search }) {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    api
      .get('/categories', {
        params: {
          idCategory: 0,
          page: 1,
          search,
        },
      })
      .then(response => {
        const data = response.data.map(category => ({
          id: category.id,
          name: category.name,
          slug: category.name.replace(/ /g, '-').toLowerCase(),
        }));
        setCategories(data);
      });
  }, [search]);

  return (
    <Content>
      <h2>Categorias</h2>
      <CategoriasList>
        {categories.length > 0 ? (
          <>
            {categories.map(category => (
              <Categoria key={category.id}>
                <Link to={`/servicos/categorias/${category.slug}`}>
                  <h3>{category.name}</h3>
                </Link>
              </Categoria>
            ))}
          </>
        ) : (
          <NotFound>
            <p>Nenhuma categoria encontrada para esse filtro.</p>
          </NotFound>
        )}
      </CategoriasList>
    </Content>
  );
}

Categorias.propTypes = {
  search: PropTypes.string,
};

Categorias.defaultProps = {
  search: '',
};
