import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import TextInputMask from 'react-masked-text';
import Input from '~/components/Input';

export default function MaskInput({
  kind,
  name,
  value,
  placeholder,
  required,
  className,
  options,
  onChange,
  disabled,
}) {
  const [newValue, setNewValue] = useState('');

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const handleChange = useCallback(
    data => {
      setNewValue(data);
      onChange(data);
    },
    [onChange]
  );

  return (
    <>
      {disabled ? (
        <TextInputMask
          kind={kind}
          options={options}
          name={name}
          placeholder={placeholder}
          className={className}
          value={newValue}
          onChangeText={handleChange}
          disabled
        />
      ) : (
        <TextInputMask
          kind={kind}
          options={options}
          name={name}
          placeholder={placeholder}
          className={className}
          value={newValue}
          onChangeText={handleChange}
        />
      )}
      <Input type="hidden" name={name} value={newValue} />
    </>
  );
}

MaskInput.propTypes = {
  kind: PropTypes.string.isRequired,
  options: PropTypes.shape({
    mask: '',
    format: '',
  }),
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

MaskInput.defaultProps = {
  options: {},
  value: '',
  placeholder: '',
  required: false,
  className: '',
  onChange: () => {},
  disabled: false,
};
