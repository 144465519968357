import React, { useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Content, Options, Option } from './styles';

import MeusDados from './MeusDados';
import MeusServicos from './MeusServicos';
import MeusEnderecos from './MeusEnderecos';

export default function Perfil() {
  const history = useHistory();
  const location = useLocation();
  const [page, setPage] = useState(location.pathname.substring(8));
  const handleSelectPage = useCallback(
    pageSelected => {
      setPage(pageSelected);
      history.push(`/perfil/${pageSelected}`);
    },
    [history]
  );

  return (
    <Content>
      <h1>Perfil</h1>
      <Options>
        <ul>
          <li className={`${page === 'meus-dados' ? 'active' : ''}`}>
            <button
              type="button"
              onClick={() => handleSelectPage('meus-dados')}
            >
              Meus <br />
              Dados
            </button>
          </li>
          <li className={`${page === 'meus-servicos' ? 'active' : ''}`}>
            <button
              type="button"
              onClick={() => handleSelectPage('meus-servicos')}
            >
              Meus <br />
              Serviços
            </button>
          </li>
          <li className={`${page === 'meus-enderecos' ? 'active' : ''}`}>
            <button
              type="button"
              onClick={() => handleSelectPage('meus-enderecos')}
            >
              Meus <br />
              Endereçoso
            </button>
          </li>
        </ul>
      </Options>
      <Option>
        {page === 'meus-dados' ? (
          <MeusDados />
        ) : (
          <>{page === 'meus-servicos' ? <MeusServicos /> : <MeusEnderecos />}</>
        )}
      </Option>
    </Content>
  );
}
