import React from 'react';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Route from './Route';

import Login from '~/pages/Login';
import Cadastro from '~/pages/Cadastro';
import EsqueciSenha from '~/pages/EsqueciSenha';
import TrocarSenha from '~/pages/TrocarSenha';

import Home from '~/pages/HomeLogado';
import Servicos from '~/pages/Servicos';
import Servico from '~/pages/Servico';
import Perfil from '~/pages/Perfil';
import Conversas from '~/pages/Conversas';
import Historico from '~/pages/Historico';
import CadastrarServico from '~/pages/CadastrarServico';
import PerfilUsuario from '~/pages/PerfilUsuario';
import EditarServico from '~/pages/EditarServico';

export default function Routes() {
  const signed = useSelector(state => state.auth.signed);
  return (
    <Switch>
      <Route
        path="/"
        exact
        component={signed ? Home : Login}
        isPrivate={signed}
      />

      <Route path="/login" component={Login} />
      <Route path="/cadastrar" component={Cadastro} />
      <Route path="/esqueci-minha-senha" component={EsqueciSenha} />
      <Route path="/trocar-senha/:token" component={TrocarSenha} />

      <Route path="/servicos" component={Servicos} exact isPrivate={signed} />
      <Route
        path="/servicos/categorias"
        component={Servicos}
        isPrivate={signed}
      />
      <Route
        path="/servicos/categorias/:slug"
        component={Servicos}
        isPrivate={signed}
      />
      <Route
        path="/servicos/destaques"
        component={Servicos}
        isPrivate={signed}
      />
      <Route
        path="/servico/:slug"
        exact
        component={Servico}
        isPrivate={signed}
      />

      <Route path="/chat" exact component={Conversas} isPrivate />
      <Route path="/chat/:slug" component={Conversas} isPrivate />
      <Route path="/historico" component={Historico} isPrivate />

      <Route path="/perfil/meus-dados" component={Perfil} exact isPrivate />
      <Route path="/perfil/meus-servicos" component={Perfil} exact isPrivate />
      <Route path="/perfil/meus-enderecos" component={Perfil} exact isPrivate />
      <Route
        path="/perfil/servico/cadastrar"
        component={CadastrarServico}
        exact
        isPrivate
      />
      <Route
        path="/perfil/meus-servicos/:slug"
        component={EditarServico}
        exact
        isPrivate
      />
      <Route path="/perfil/:slug" component={PerfilUsuario} isPrivate />
    </Switch>
  );
}
