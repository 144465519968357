/* eslint-disable radix */
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { IoMdMore, IoMdSend, IoMdArrowBack } from 'react-icons/io';
import { FaSearch } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import {
  format,
  differenceInHours,
  differenceInDays,
  parseISO,
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { formatToTimeZone } from 'date-fns-timezone';
import socket from 'socket.io-client';

import api from '~/services/api';
import history from '~/services/history';

import {
  Container,
  Content,
  Chats,
  Search,
  Users,
  User,
  ChatGroup,
  Form,
  HeaderChat,
  ProposalOptions,
  Chat,
  GroupButtons,
  FormModal,
  Title,
  InputGroup,
  ButtonModal,
} from './styles';
import Input from '~/components/Input';
import Modal from '~/components/Modal';
import MaskInput from '~/components/MaskInput';
import Select from '~/components/Select';

const io = socket.connect(process.env.REACT_APP_API_URL, { reconnect: true });

export default function Conversas() {
  const id = useSelector(state => state.auth.user.id);
  const params = useParams();
  const [opened, setOpened] = useState(false);
  const [openedGenerateProposal, setOpenedGenerateProposal] = useState(false);
  const [openedRequestProposal, setOpenedRequestProposal] = useState(false);
  const [talks, setTalks] = useState([]);
  const [selectedChat, setSelectedChat] = useState({});
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [pageTalks, setPageTalks] = useState(0);
  const [responseMensagem, setResponseMensagem] = useState({});
  const [myServices, setMyServices] = useState([]);
  const [youServices, setYouServices] = useState([]);
  const [newMessagesList, setNewMessagesList] = useState([]);

  const formatDate = useCallback(date => {
    const today = new Date();
    let dateDiff = differenceInHours(today, parseISO(date));
    if (dateDiff > 23) {
      dateDiff = differenceInDays(today, parseISO(date));
      if (dateDiff === 2) {
        dateDiff = 'ontem';
      } else {
        dateDiff = formatToTimeZone(date, 'DD/MM/YYYY', {
          timeZone: 'America/Sao_Paulo',
        });
      }
    } else {
      dateDiff = format(parseISO(date), 'HH:mm', {
        locale: ptBR,
      });
    }

    return dateDiff;
  }, []);

  const loadTalks = useCallback(
    async (oldTalks = false) => {
      if (pageTalks === 0 || oldTalks) {
        const response = await api.get(`talks/${id}`, {
          params: {
            page: pageTalks + 1,
          },
        });

        if (response.data.length > 0) {
          const data = response.data.map(talk => {
            const dateDiff = formatDate(talk.updatedAt);
            return {
              id: talk.id,
              from: talk.userOne.id === id ? talk.userOne.id : talk.userTwo.id,
              to: talk.userOne.id !== id ? talk.userOne.id : talk.userTwo.id,
              name:
                talk.userOne.id !== id ? talk.userOne.name : talk.userTwo.name,
              avatar:
                talk.userOne.id !== id
                  ? talk.userOne.avatar.url
                  : talk.userTwo.avatar.url,
              lastMessage:
                talk.last_message && talk.last_message.match(/<div.*/)
                  ? 'Proposta'
                  : talk.last_message,
              lastMessageRead: talk.last_message_read,
              lastMessageTime: dateDiff,
            };
          });
          setTalks(state => [...state, ...data]);
          setPageTalks(state => state + 1);
        }
      }
    },
    [formatDate, id, pageTalks]
  );

  const handleReceptResponse = useCallback(
    async messageResponse => {
      const indexMessage = messages.findIndex(
        message => message.id === messageResponse.id
      );
      if (indexMessage >= 0) {
        const chatMessages = messages;
        chatMessages[indexMessage].message = messageResponse.message;
        chatMessages[indexMessage].respondido = messageResponse.answered;

        setNewMessagesList(chatMessages);
      }
    },
    [messages]
  );

  const loadMessages = useCallback(async talk => {
    const response = await api.get(`chat/${talk.from}/${talk.to}`, {
      params: {
        page: 1,
      },
    });

    const data = response.data.map(message => {
      const isMyHtml =
        message.from === talk.from && message.isHtml && !message.answered
          ? '<h3 style="text-align: center; color: #fff;">Aguardando Resposta...</h3>'
          : '';
      return {
        id: message._id,
        from: message.from,
        to: message.to,
        message: message.message + isMyHtml,
        html: message.isHtml,
        respondido: message.answered,
      };
    });
    setSelectedChat(talk);
    setMessages(data);
  }, []);

  const selectChat = useCallback(talk => {
    const name = talk.name.toLowerCase().replace(' ', '-');
    history.push(`/chat/${name}-${talk.from}-${talk.to}`);
  }, []);

  useEffect(() => {
    loadTalks();
  }, [loadTalks]);

  useEffect(() => {
    if (params.slug) {
      if (talks.length > 0) {
        const idsSelected = params.slug.replace(/\D/gim, ' ').trim();
        const name = params.slug.replace(/[^a-zA-Zs]/g, ' ').trim();
        const ids = idsSelected.split(' ');
        const userOne = parseInt(ids[0]);
        const userTwo = parseInt(ids[1]);
        const talkSelected = talks.find(
          talk =>
            ((talk.from === userOne && talk.to === userTwo) ||
              (talk.from === userTwo && talk.to === userOne)) &&
            talk.name.toLowerCase() === name
        );
        if (talkSelected) {
          loadMessages(talkSelected);
        } else {
          history.push('/chat');
        }
      }
    }
  }, [loadMessages, params.slug, selectChat, talks]);

  useEffect(() => {
    document.addEventListener('click', evt => {
      const proposalOptions = document.getElementById('proposalOptions');
      const proposalOptionsButton = document.getElementById(
        'proposalOptionsButton'
      );
      let targetElement = evt.target; // clicked element

      do {
        if (
          targetElement === proposalOptions ||
          targetElement === proposalOptionsButton
        ) {
          return;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      setOpened(false);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(selectedChat).length > 0) {
      io.emit('connectRoom', `chat_${selectedChat.id}`);
      io.emit('connectRoom', `conversasUser_${id}`);

      io.on('chat', data => {
        if (data.from !== selectedChat.from && data.to === id) {
          setResponseMensagem({
            id: data._id,
            from: data.from,
            to: data.to,
            message: data.message,
            html: data.isHtml,
            respondido: data.answered,
          });
        }
      });

      io.on('conversasUser', data => {
        const talkSelected = talks.find(talk => talk.id === data.id);
        const oldTalks = talks.filter(talk => talk.id !== data.id);
        const dateDiff = formatDate(data.updatedAt);

        talkSelected.lastMessage = data.last_message.match(/<div.*/)
          ? 'Proposta'
          : data.last_message;
        talkSelected.lastMessageRead = data.last_message_read;
        talkSelected.lastMessageTime = dateDiff;

        setTalks([talkSelected, ...oldTalks]);
      });

      io.on('responseProposal', data => {
        handleReceptResponse(data);
      });
    }
  }, [
    formatDate,
    handleReceptResponse,
    id,
    loadTalks,
    selectedChat,
    selectedChat.from,
    selectedChat.id,
    talks,
  ]);

  useEffect(() => {
    if (responseMensagem.message) {
      const exist = messages.filter(
        message => message.id === responseMensagem.id
      );
      if (exist.length === 0) {
        setMessages(state => [responseMensagem, ...state]);
        setResponseMensagem('');
      }
    }
  }, [messages, responseMensagem]);

  useEffect(() => {
    if (selectedChat.from) {
      api.get(`/services/user/${selectedChat.from}`).then(response => {
        const data = response.data.map(service => ({
          value: service.id,
          label: service.name,
        }));
        setMyServices(data);
      });

      api.get(`/services/user/${selectedChat.to}`).then(response => {
        const data = response.data.map(service => ({
          value: service.id,
          label: service.name,
        }));
        setYouServices(data);
      });
    }
  }, [id, selectedChat, selectedChat.from, selectedChat.to]);

  useEffect(() => {
    setMessages(newMessagesList);
  }, [newMessagesList]);

  const handleSubmit = useCallback(
    async ({ message }) => {
      const e = document.getElementById('message');
      e.value = '';

      const response = await api.post('chat', {
        message,
        from: selectedChat.from,
        to: selectedChat.to,
        conversaId: selectedChat.id,
      });

      setMessages(state => [
        {
          id: response.data._id,
          from: response.data.from,
          to: response.data.to,
          message: response.data.message,
          html: false,
          respondido: false,
        },
        ...state,
      ]);
    },
    [selectedChat.from, selectedChat.id, selectedChat.to]
  );

  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        const data = { message: e.target.value };
        e.target.value = '';
        handleSubmit(data);
      }
    },
    [handleSubmit]
  );

  const handleGenerateProposal = useCallback(
    async ({
      serviceGenerateProposal,
      title,
      price,
      observations,
      delivery_date,
    }) => {
      const message = `
        <div style="width: 100%; background-color: #fff; padding: 10px; border-radius: 10%">
          <p style="text-align: center; margin-bottom: 0; color: #173E4F; font-size: 18px;">${title}</p>
          <p style="text-align: center; margin-bottom: 0; color: #C5CCD6; font-size: 12px;">Valor: ${price}</p>
          <p style="text-align: left; margin-bottom: 0; color: #E4E6E9; font-size: 12px;"><b style="color: #9DA3B4; font-size: 14px;">Data de entrega:</b> ${observations ||
            'não possui'}</p>
          <p style="text-align: left; margin-bottom: 0; color: #E4E6E9; font-size: 12px;"><b style="color: #9DA3B4; font-size: 14px;">Observação:</b> ${delivery_date ||
            'não possui'}</p>
        </div>
      `;
      const response = await api.post('chat', {
        message,
        from: selectedChat.from,
        to: selectedChat.to,
        conversaId: selectedChat.id,
        isHtml: true,
      });

      if (response.data) {
        if (delivery_date) {
          const dateParts = delivery_date.split('/');
          delivery_date = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        } else {
          delivery_date = null;
        }

        await api.post('proposal', {
          id_message: response.data._id,
          id_service: serviceGenerateProposal,
          id_user_one: selectedChat.from,
          id_user_two: selectedChat.to,
          title,
          value: price
            .replace('R$', '')
            .replace('.', '')
            .replace(',', '.'),
          delivery_date,
          note: observations || null,
        });

        setMessages(state => [
          {
            id: response.data._id,
            from: selectedChat.from,
            to: selectedChat.to,
            message: `${message.replace(
              '</div>',
              ''
            )}<h3 style="text-align: center; color: #92AEB9; font-size: 12px;">Aguardando Resposta...</h3></div>`,
            html: true,
            respondido: false,
          },
          ...state,
        ]);
        setOpenedGenerateProposal(false);
      }
    },
    [selectedChat.from, selectedChat.id, selectedChat.to]
  );

  const handleRequestProposal = useCallback(
    async ({ serviceNewProposal }) => {
      const serviceSelected = youServices.find(
        // eslint-disable-next-line radix
        youService => youService.value === parseInt(serviceNewProposal)
      );

      const message = `Gostaria de receber uma proposta para o seu serviço: ${serviceSelected.label}`;
      const response = await api.post('chat', {
        message,
        from: selectedChat.from,
        to: selectedChat.to,
        conversaId: selectedChat.id,
      });

      setMessages(state => [
        {
          id: response.data._id,
          from: selectedChat.from,
          to: selectedChat.to,
          message,
          html: false,
          respondido: false,
        },
        ...state,
      ]);
      setOpenedRequestProposal(false);
    },
    [selectedChat.from, selectedChat.id, selectedChat.to, youServices]
  );

  const loadOldTalks = useCallback(() => {
    loadTalks(true);
  }, [loadTalks]);

  const loadOldMessages = useCallback(async () => {
    const response = await api.get(
      `chat/${selectedChat.from}/${selectedChat.to}`,
      {
        params: {
          page: page + 1,
        },
      }
    );

    if (response.data.length > 0) {
      const data = response.data.map(message => {
        return {
          id: message._id,
          from: message.from,
          to: message.to,
          message: message.message,
          html: message.isHtml,
          respondido: false,
        };
      });
      setMessages(state => [...state, ...data]);
      setPage(state => state + 1);
    }
  }, [page, selectedChat]);

  const handleSearch = useCallback(
    async e => {
      const like = e.target.value;
      const response = await api.get(`/talks/${id}`, {
        params: {
          page: 1,
          search: like,
        },
      });

      const data = response.data.map(talk => {
        const dateDiff = formatDate(talk.updatedAt);
        return {
          id: talk.id,
          from: talk.userOne.id === id ? talk.userOne.id : talk.userTwo.id,
          to: talk.userOne.id !== id ? talk.userOne.id : talk.userTwo.id,
          name: talk.userOne.id !== id ? talk.userOne.name : talk.userTwo.name,
          avatar:
            talk.userOne.id !== id
              ? talk.userOne.avatar.url
              : talk.userTwo.avatar.url,
          lastMessage: talk.last_message,
          lastMessageRead: talk.last_message_read,
          lastMessageTime: dateDiff,
        };
      });
      setTalks(data);
    },
    [formatDate, id]
  );

  const handleRecusar = useCallback(
    async idMessage => {
      const response = await api.put(`proposal/${idMessage}`, {
        declined_at: new Date(),
        status: 4,
        status_name: 'Recusado',
      });

      const indexMessage = messages.findIndex(
        message => message.id === idMessage
      );

      const chatMessages = messages;
      if (!chatMessages[indexMessage].respondido) {
        chatMessages[indexMessage].message = `${chatMessages[
          indexMessage
        ].message.replace(
          '</div>',
          ''
        )}<h3 style="color: #b21f2d; text-align: center; font-size: 12px;">Recusado</h3></div>`;
        chatMessages[indexMessage].respondido = true;
      }

      await api.put(`chat/${idMessage}`, {
        id_talk: selectedChat.id,
        message: chatMessages[indexMessage].message,
        answered: chatMessages[indexMessage].respondido,
      });

      if (response.data) {
        setNewMessagesList(chatMessages);
      }
    },
    [messages, selectedChat.id]
  );

  const handleAceitar = useCallback(
    async idMessage => {
      const response = await api.put(`proposal/${idMessage}`, {
        aproved_at: new Date(),
        status: 1,
        status_name: 'Em Andamento',
      });

      const indexMessage = messages.findIndex(
        message => message.id === idMessage
      );
      const chatMessages = messages;
      if (!chatMessages[indexMessage].respondido) {
        chatMessages[indexMessage].message = `${chatMessages[
          indexMessage
        ].message.replace(
          '</div>',
          ''
        )}<h3 style="color: #28a745; text-align: center; font-size: 12px;">Aceito</h3></div>`;
        chatMessages[indexMessage].respondido = true;
      }

      await api.put(`chat/${idMessage}`, {
        id_talk: selectedChat.id,
        message: chatMessages[indexMessage].message,
        answered: chatMessages[indexMessage].respondido,
      });

      if (response.data) {
        setNewMessagesList(chatMessages);
      }
    },
    [messages, selectedChat.id]
  );

  const handleReturnChatList = useCallback(() => {
    setSelectedChat({});
    history.push('/chat');
  }, []);

  return (
    <>
      <Container>
        <h1 className={Object.keys(selectedChat).length > 0 ? 'hidden' : ''}>
          Chat
        </h1>
        <Content>
          <Chats hiddenMobile={Object.keys(selectedChat).length > 0}>
            <Search>
              <FaSearch size={24} color="#FFCA18" />
              <input
                name="search"
                placeholder="Pesquisar"
                onChange={handleSearch}
              />
            </Search>
            <Users
              scrollLoadThreshold={100}
              onInfiniteLoad={loadOldTalks}
              key={talk => talk.id}
            >
              {talks.map(talk => (
                <User key={talk.id} onClick={() => selectChat(talk)}>
                  <div>
                    <img src={talk.avatar} alt={talk.name} />
                  </div>
                  <div>
                    <h3>{talk.name}</h3>
                    <p>{talk.lastMessage}</p>
                  </div>
                  <div>
                    <p>
                      <small>{talk.lastMessageTime}</small>
                    </p>
                  </div>
                </User>
              ))}
            </Users>
          </Chats>
          <ChatGroup showMobile={Object.keys(selectedChat).length > 0}>
            {Object.keys(selectedChat).length > 0 ? (
              <>
                <HeaderChat>
                  <div>
                    <button
                      type="button"
                      className="arrow"
                      onClick={handleReturnChatList}
                    >
                      <IoMdArrowBack size={20} color="#8c8888" />
                    </button>
                    <img src={selectedChat.avatar} alt={selectedChat.name} />
                    <h2>{selectedChat.name}</h2>
                  </div>
                  <div>
                    <button
                      type="button"
                      id="proposalOptionsButton"
                      onClick={() => setOpened(!opened)}
                    >
                      <IoMdMore size={25} color="#173e4f" />
                    </button>
                    <ProposalOptions id="proposalOptions" opened={opened}>
                      <ul>
                        {myServices.length > 0 && (
                          <li>
                            <button
                              type="button"
                              className="modalButton"
                              onClick={() => setOpenedGenerateProposal(true)}
                            >
                              Gerar Proposta
                            </button>
                          </li>
                        )}
                        {youServices.length > 0 && (
                          <li>
                            <button
                              type="button"
                              className="modalButton"
                              onClick={() => setOpenedRequestProposal(true)}
                            >
                              Pedir Proposta
                            </button>
                          </li>
                        )}
                      </ul>
                    </ProposalOptions>
                  </div>
                </HeaderChat>
                <Chat
                  className="content"
                  flipped
                  scrollLoadThreshold={100}
                  onInfiniteLoad={loadOldMessages}
                  key={message => message.id}
                >
                  {messages.map(message => (
                    <Fragment key={message.id}>
                      {message.html ? (
                        <div
                          className={`${
                            message.from === id ? 'meContent' : 'youContent'
                          }`}
                        >
                          <div
                            className={`${message.from === id ? 'me' : 'you'}`}
                          >
                            <div // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: message.message,
                              }}
                            />
                            {message.from !== id && !message.respondido && (
                              <GroupButtons>
                                <button
                                  type="button"
                                  className="recusar"
                                  onClick={() => handleRecusar(message.id)}
                                >
                                  Recusar
                                </button>
                                <button
                                  type="button"
                                  className="aceitar"
                                  onClick={() => handleAceitar(message.id)}
                                >
                                  Aceitar
                                </button>
                              </GroupButtons>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`${
                            message.from === id ? 'meContent' : 'youContent'
                          }`}
                        >
                          <div
                            className={`${message.from === id ? 'me' : 'you'}`}
                          >
                            <p>{message.message}</p>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ))}
                </Chat>
                <Form onSubmit={handleSubmit}>
                  <Input
                    id="message"
                    name="message"
                    multiline
                    placeholder="Digite algo..."
                    onKeyPress={handleKeyPress}
                  />
                  <button type="submit">
                    <IoMdSend size={20} color="#fff" />
                  </button>
                </Form>
              </>
            ) : (
              ''
            )}
          </ChatGroup>
        </Content>
      </Container>
      <Modal
        opened={openedGenerateProposal}
        close={() => setOpenedGenerateProposal(false)}
      >
        <Title>Gerar Nova Proposta</Title>
        <FormModal onSubmit={handleGenerateProposal} hauto={1}>
          <InputGroup>
            <div>
              <label htmlFor="title">Título</label>
              <Input name="title" />
            </div>
            <div>
              <label htmlFor="service">Serviço</label>
              <Select
                name="serviceGenerateProposal"
                placeholder="Selecione"
                options={myServices}
              />
            </div>
          </InputGroup>
          <InputGroup>
            <div>
              <label htmlFor="price">Valor</label>
              <MaskInput
                name="price"
                placeholder="R$"
                kind="money"
                options={{
                  precision: 2,
                  separator: ',',
                  delimiter: '.',
                  unit: 'R$',
                  suffixUnit: '',
                }}
              />
            </div>
            <div>
              <label htmlFor="delivery_date">Data de Entrega</label>
              <MaskInput
                name="delivery_date"
                placeholder="xx/xx/xxxx"
                kind="custom"
                options={{
                  mask: '99/99/9999',
                }}
              />
            </div>
          </InputGroup>
          <InputGroup>
            <div>
              <label htmlFor="observations">Observações</label>
              <Input name="observations" multiline />
            </div>
          </InputGroup>
          <ButtonModal>
            <button type="submit">Enviar</button>
          </ButtonModal>
        </FormModal>
      </Modal>
      <Modal
        opened={openedRequestProposal}
        close={() => setOpenedRequestProposal(false)}
      >
        <Title>Solicitar Nova Proposta</Title>
        <FormModal onSubmit={handleRequestProposal} hauto={1}>
          <InputGroup>
            <div>
              <label htmlFor="service">Selecione o serviço do prestador</label>
              <Select
                name="serviceNewProposal"
                placeholder="Selecione"
                options={youServices}
              />
            </div>
          </InputGroup>
          <ButtonModal>
            <button type="submit">Enviar</button>
          </ButtonModal>
        </FormModal>
      </Modal>
    </>
  );
}
