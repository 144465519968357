/* eslint-disable radix */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { RadioButton, InputsGroup, InputGroup } from './styles';
import Select from '~/components/Select';

export default function Adresses({
  data,
  index,
  editable,
  setEditable,
  handleChangeAdress,
  isMain,
  setIsMain,
}) {
  const [cep, setCep] = useState(data.cep);
  const [street, setStreet] = useState(data.street);
  const [number, setNumber] = useState(data.number);
  const [neighborhood, setNeighborhood] = useState(data.neighborhood);
  const [city, setCity] = useState(data.city);
  const [state, setState] = useState(data.state);
  const [complement, setComplement] = useState(data.complement);
  const [stateSelected, setStateSelected] = useState({});

  useEffect(() => {
    const address = {
      id: data.id,
      cep,
      street,
      number,
      neighborhood,
      city,
      state,
      complement,
    };
    handleChangeAdress(index, address);
  }, [
    cep,
    city,
    complement,
    data.id,
    handleChangeAdress,
    index,
    neighborhood,
    number,
    state,
    street,
  ]);

  const handleChangeCep = useCallback(value => {
    setCep(value);
    if (value.length === 9) {
      axios.get(`https://viacep.com.br/ws/${value}/json/`).then(response => {
        setStreet(response.data.logradouro);
        setNeighborhood(response.data.bairro);
        setCity(response.data.localidade);
        setState(response.data.uf);
      });
    }
  }, []);

  const states = useMemo(() => {
    return [
      { value: 'AC', label: 'Acre' },
      { value: 'AL', label: 'Alagoas' },
      { value: 'AP', label: 'Amapá' },
      { value: 'AM', label: 'Amazonas' },
      { value: 'BA', label: 'Bahia' },
      { value: 'CE', label: 'Ceará' },
      { value: 'DF', label: 'Distrito Federal' },
      { value: 'ES', label: 'Espirito Santo' },
      { value: 'GO', label: 'Goiás' },
      { value: 'MA', label: 'Maranhão' },
      { value: 'MS', label: 'Mato Grosso do Sul' },
      { value: 'MT', label: 'Mato Grosso' },
      { value: 'MG', label: 'Minas Gerais' },
      { value: 'PA', label: 'Pará' },
      { value: 'PB', label: 'Paraíba' },
      { value: 'PR', label: 'Paraná' },
      { value: 'PE', label: 'Pernambuco' },
      { value: 'PI', label: 'Piauí' },
      { value: 'RJ', label: 'Rio de Janeiro' },
      { value: 'RN', label: 'Rio Grande do Norte' },
      { value: 'RS', label: 'Rio Grande do Sul' },
      { value: 'RO', label: 'Rondônia' },
      { value: 'RR', label: 'Roraima' },
      { value: 'SC', label: 'Santa Catarina' },
      { value: 'SP', label: 'São Paulo' },
      { value: 'SE', label: 'Sergipe' },
      { value: 'TO', label: 'Tocantins' },
    ];
  }, []);

  useEffect(() => {
    const selected = states.find(stateCheck => stateCheck.value === state);
    setStateSelected(selected);
  }, [data.id, state, states]);

  return (
    <InputsGroup>
      <RadioButton>
        <input
          type="radio"
          name="principal"
          id="endereco"
          checked={parseInt(isMain) === data.id}
          value={data.id}
          onChange={e => setIsMain(e.target.value)}
        />
        <label htmlFor="endereco">Usar este endereço como principal</label>
      </RadioButton>
      <InputGroup>
        <div>
          <label htmlFor="cep">Cep</label>
          <div className="cep">
            <input
              name="cep"
              value={cep}
              className={`${editable ? 'active' : ''}`}
              onChange={e => handleChangeCep(e.target.value)}
              disabled={!editable}
            />
            <button
              type="button"
              className={`${editable ? 'active' : ''}`}
              onClick={() => setEditable(true)}
            >
              Editar
            </button>
          </div>
        </div>
        <div>
          <label htmlFor="street">rua</label>
          <div>
            <input
              name="street"
              value={street}
              className={`${editable ? 'active' : ''}`}
              onChange={e => setStreet(e.target.value)}
              disabled={!editable}
            />
            <button
              type="button"
              className={`${editable ? 'active' : ''}`}
              onClick={() => setEditable(true)}
            >
              Editar
            </button>
          </div>
        </div>
      </InputGroup>
      <InputGroup>
        <div>
          <label htmlFor="number">Número</label>
          <div>
            <input
              name="number"
              value={number}
              className={`${editable ? 'active' : ''}`}
              onChange={e => setNumber(e.target.value)}
              disabled={!editable}
            />
            <button
              type="button"
              className={`${editable ? 'active' : ''}`}
              onClick={() => setEditable(true)}
            >
              Editar
            </button>
          </div>
        </div>
        <div>
          <label htmlFor="neighborhood">Bairro</label>
          <div>
            <input
              name="neighborhood"
              value={neighborhood}
              className={`${editable ? 'active' : ''}`}
              onChange={e => setNeighborhood(e.target.value)}
              disabled={!editable}
            />
            <button
              type="button"
              className={`${editable ? 'active' : ''}`}
              onClick={() => setEditable(true)}
            >
              Editar
            </button>
          </div>
        </div>
      </InputGroup>
      <InputGroup>
        <div>
          <label htmlFor="city">Cidade</label>
          <div>
            <input
              name="city"
              value={city}
              className={`${editable ? 'active' : ''}`}
              onChange={e => setCity(e.target.value)}
              disabled={!editable}
            />
            <button
              type="button"
              className={`${editable ? 'active' : ''}`}
              onClick={() => setEditable(true)}
            >
              Editar
            </button>
          </div>
        </div>
        <div>
          <label htmlFor="state">Estado</label>
          <div>
            <Select
              name="state"
              options={states}
              selected={stateSelected}
              disabled={!editable}
              handleSelect={value => setState(value)}
            />
            <button
              type="button"
              className={`${editable ? 'active' : ''}`}
              onClick={() => setEditable(true)}
            >
              Editar
            </button>
          </div>
        </div>
      </InputGroup>
      <InputGroup>
        <div>
          <label htmlFor="complement">Complemento</label>
          <div>
            <input
              name="complement"
              value={complement}
              className={`${editable ? 'active' : ''}`}
              onChange={e => setComplement(e.target.value)}
              disabled={!editable}
            />
            <button
              type="button"
              className={`${editable ? 'active' : ''}`}
              onClick={() => setEditable(true)}
            >
              Editar
            </button>
          </div>
        </div>
      </InputGroup>
    </InputsGroup>
  );
}

Adresses.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    cep: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    neighborhood: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    complement: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
  setEditable: PropTypes.func.isRequired,
  handleChangeAdress: PropTypes.func.isRequired,
  isMain: PropTypes.string.isRequired,
  setIsMain: PropTypes.func.isRequired,
};
