import styled, { css } from 'styled-components';
import { Form as FormComponent } from '@unform/web';
import { lighten } from 'polished';

export const Content = styled.div`
  > div {
    h1 {
      color: #1d2433;
      font-weight: 500;
      font-size: 4rem;
    }

    h2 {
      color: #9da3b4;
      font-size: 2rem;
      font-weight: 500;
    }
  }
`;

export const Form = styled(FormComponent)`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
  ${props =>
    !props.hauto
      ? css`
          min-height: 75vh;
        `
      : ''};

  @media screen and (max-width: 991px) {
    ${props =>
      !props.hauto
        ? css`
            min-height: 75vh;
          `
        : css`
            height: 75vh;
            overflow-y: auto;
            padding: 0px;
          `};
  }
`;

export const Timeline = styled.div`
  > div {
    padding: 10px 20px;
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      border: 1px solid #b7ccd4;
      border-radius: 50%;
      transition-duration: 0.3s;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        color: #b7ccd4;
        width: 50px;
        height: 50px;
        transition-duration: 0.3s;
      }
    }

    div.separator {
      width: 30px;
      border-radius: 0;
      height: 1px;
      border: none;
      background-color: #b7ccd4;
    }

    ${props => {
      switch (props.passo) {
        case 2:
          return css`
            div {
              :nth-child(1),
              :nth-child(3) {
                border: 2px solid #173e4f;
                span {
                  color: #173e4f;
                  font-weight: 600;
                }
              }

              :nth-child(2) {
                height: 2px;
                background-color: #173e4f;
              }
            }
          `;

        case 3:
          return css`
            div {
              :nth-child(1),
              :nth-child(3),
              :nth-child(5) {
                border: 2px solid #173e4f;
                span {
                  color: #173e4f;
                  font-weight: 600;
                }
              }

              :nth-child(2),
              :nth-child(4) {
                height: 2px;
                background-color: #173e4f;
              }
            }
          `;

        default:
          return css`
            div {
              :nth-child(1) {
                border: 2px solid #173e4f;
                span {
                  color: #173e4f;
                  font-weight: 600;
                }
              }
            }
          `;
      }
    }}
  }

  h3 {
    font-size: 2rem;
    color: #173e4f;
    text-align: center;
  }

  @media screen and (max-width: 991px) {
    > div {
      width: 100%;

      div {
        span {
          font-size: 2.5rem;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
`;

export const PassoGroup = styled.div`
  position: relative;
  height: 330px;
  margin: 10px 0;
`;

export const Passo = styled.div`
  width: 50%;
  margin: 0 auto;
  transition-duration: 0.3s;
  visibility: ${props => (props.active ? 'visibility' : 'hidden')};
  opacity: ${props => (props.active ? '1' : '0')};
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow-y: auto;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const InputGroup = styled.div`
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;

  label {
    font-size: 1.5rem;
    color: #c5ccd6;
  }

  > div {
    padding: 10px;
    width: 100%;

    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid #d8d8d8;
      height: 40px;
      font-size: 1.5rem;
      color: #323643;
      padding: 0 10px;
    }

    input.active {
      border-bottom: 1px solid #323643;
    }

    textarea {
      display: block;
      width: 100%;
      border: 1px solid #d8d8d8;
      height: 100px;
      border-radius: 10px;
      margin-top: 15px;
      resize: none;
      padding: 10px;
    }

    > div {
      display: flex;
      align-items: center;

      label {
        padding: 0 5px;
      }
    }

    .css-2b097c-container {
      display: block;
    }
  }

  @media screen and (max-width: 991px) {
    display: block;

    > div {
      input {
        height: 20px;
      }
    }
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding: 0 10px 10px;
  border-bottom: 1px solid #d8d8d8;

  > div {
    input {
      visibility: hidden;
      margin-right: 8px;
      width: 18px;
      height: 18px;
      position: relative;
      top: 5px;

      :before {
        content: '';
        background-color: transparent;
        border: 2px solid #c5ccd67a;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
      }

      :after {
        content: '';
        background-color: transparent;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: visible;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      :checked:after {
        background-color: #323643;
      }
    }

    label {
      h3 {
        color: #c5ccd6;
        font-size: 2rem;
        font-weight: 500;
      }

      p {
        color: #9da3b4;
        font-size: 1.5rem;
      }
    }
  }
`;

export const ButtonNewAddress = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  padding: 0 10px;
  transition-duration: 0.3s;

  b {
    font-size: 2rem;
    margin-right: 5px;
  }

  p {
    font-size: 1.8rem;
  }

  :hover {
    opacity: 0.6;
  }
`;

export const InputFileGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FilePreview = styled.div`
  background-image: url(${props => props.file});
  background-size: cover;
  width: 150px;
  height: 150px;
  border-radius: 15px;
  border: none;
  background-color: none;
  margin: 10px;

  button {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: none;
    background-color: rgba(0, 0, 0, 0.3);
  }

  :hover {
    button {
      visibility: visible;
      opacity: 1;
      transition: 0.3s;
    }
  }

  @media screen and (max-width: 991px) {
    width: 45%;
    height: 108px;
    margin: 5px;

    button {
      visibility: visible;
      opacity: 1;
      transition: 0.3s;
    }
  }
`;

export const DropzoneContainer = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 15px;
  border: 1px dashed #c5ccd6;
  background-color: none;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  p {
    color: #c5ccd6;
  }

  @media screen and (max-width: 991px) {
    width: 45%;
    height: 108px;
    margin: 5px;
  }
`;

export const ButtonGroup = styled.div`
  padding: 10px 20px;
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: ${props =>
    props.passo === 1 ? 'flex-end' : 'space-between'};

  button {
    border: none;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #173e4f;
    color: #fff;
    border-radius: 50px;
    height: 30px;
    margin-bottom: 15px;
    transition-duration: 0.3s;

    :hover {
      background-color: ${lighten(0.1, '#173E4F')};
    }
  }

  .d-none {
    display: none;
  }

  @media screen and (max-width: 991px) {
    width: 100%;

    button {
      width: 49%;
    }
  }
`;

export const Title = styled.h2`
  color: #1d2433;
  font-size: 5rem;
  font-weight: 500;
  margin-bottom: -30px;

  @media screen and (max-width: 991px) {
    font-size: 3rem;
    text-align: center;
    margin-bottom: -25px;
  }
`;

export const ButtonModal = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 20px;

  button {
    border: none;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #173e4f;
    color: #fff;
    border-radius: 50px;
    height: 30px;
    margin-bottom: 15px;
    transition-duration: 0.3s;

    :hover {
      background-color: ${lighten(0.1, '#173E4F')};
    }
  }

  @media screen and (max-width: 991px) {
    button {
      width: 90%;
      margin: 0 auto;
    }
  }
`;
