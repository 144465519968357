import styled from 'styled-components';
import { shade, lighten } from 'polished';

export const Content = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 991px) {
    display: block;
  }
`;

export const AvatarGroup = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    button.changeAvatar {
      display: flex;
      margin-bottom: 25px;
      border: none;
      background: none;
      transition-duration: 0.3s;

      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background: #ccc;
        display: block;
      }

      :hover {
        opacity: 0.7;
      }
    }

    a {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #173e4f;
      border: 1px solid #173e4f;
      border-radius: 50px;
      height: 30px;
      margin-bottom: 15px;
      transition-duration: 0.3s;

      :hover {
        color: ${lighten(0.1, '#173E4F')};
        border-color: ${lighten(0.1, '#173E4F')};
      }
    }

    button.changePass {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #173e4f;
      border: 1px solid #173e4f;
      color: #fff;
      border-radius: 50px;
      height: 30px;
      transition-duration: 0.3s;

      :hover {
        border-color: ${lighten(0.1, '#173E4F')};
        background-color: ${lighten(0.1, '#173E4F')};
      }
    }
  }
`;

export const FormGroup = styled.div`
  width: 75%;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  padding: 0 10px;

  h2 {
    padding: 0 10px;
    margin: 20px 0 15px;
    font-size: 3.5rem;
    font-weight: 500;
    color: #1d2433;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 10px;

    h2 {
      text-align: center;
      font-size: 3rem;
      margin: 0;
    }
  }
`;

export const Inputs = styled.div`
  max-height: 310px;
  overflow-y: auto;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button {
    margin: 0 10px;
    padding: 0 10px;
    width: 150px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffca18;
    border-radius: 50px;
    color: #ffca18;
    margin-bottom: 15px;
    background: none;
    opacity: 0;
    visibility: hidden;
    transition-duration: 0.3s;

    :hover {
      color: ${shade(0.2, '#FFCA18')};
      border-color: ${shade(0.2, '#FFCA18')};
    }
  }

  button.active {
    opacity: 1;
    visibility: visible;
  }

  @media screen and (max-width: 991px) {
    button {
      margin: 10px 0 0 0;
      width: 100%;
    }
  }
`;
