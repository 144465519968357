/* eslint-disable radix */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Form } from '@unform/web';
import { useSelector, useDispatch } from 'react-redux';
import { parseISO, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import api from '~/services/api';
import Input from '~/components/Input';

import { updateRequest } from '~/store/modules/auth/actions';

import { Content, FormGroup, InputsGroup } from './styles';
import AvatarGroup from '~/components/AvatarGroup';
import InputRadio from '~/components/InputRadio';
import MaskInput from '~/components/MaskInput';
import Select from '~/components/Select';

function MeusDados() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  console.log(user);
  const [editable, setEditable] = useState(false);
  const [sex, setSex] = useState(user.sex);
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);

  useEffect(() => {
    api.get('categories').then(response => {
      const data = response.data.map(category => ({
        value: category.id,
        label: category.name,
      }));
      const selected = data.find(
        category => category.value === parseInt(user.id_category)
      );
      setCategorySelected(selected);
      setCategories(data);
    });
  }, [user, user.id_category]);

  const handleSubmit = useCallback(
    ({
      name,
      email,
      birthday,
      rg,
      document,
      company_name,
      celphone,
      tellphone,
      category,
    }) => {
      const [day, month, year] = birthday.split('/');
      const id_category = !category ? categorySelected.id : category;

      dispatch(
        updateRequest(
          user.id,
          user.id_avatar,
          name,
          email,
          user.person_type,
          `${year}-${month}-${day}`,
          sex,
          rg,
          document,
          company_name,
          celphone,
          tellphone,
          id_category
        )
      );
    },
    [categorySelected, dispatch, sex, user.id, user.id_avatar, user.person_type]
  );

  const sexData = useMemo(() => {
    return [
      { value: 'M', label: 'Masculino', selected: sex === 'M' },
      { value: 'F', label: 'Feminino', selected: sex === 'F' },
    ];
  }, [sex]);

  const birthdayData = useMemo(() => {
    return format(parseISO(user.birthday), 'dd/MM/yyyy', {
      locale: ptBR,
    });
  }, [user.birthday]);

  const handleChange = useCallback(value => {
    setSex(value);
  }, []);

  return (
    <Content>
      <AvatarGroup />
      <FormGroup>
        <h2>Meus Dados</h2>
        <Form initialData={user} onSubmit={handleSubmit}>
          <div>
            <InputsGroup>
              <label htmlFor="name">Nome</label>
              <div>
                <Input
                  name="name"
                  className={`${editable ? 'active' : ''}`}
                  disabled={!editable}
                />
                <button
                  type="button"
                  className={`${editable ? 'active' : ''}`}
                  onClick={() => setEditable(true)}
                >
                  Editar
                </button>
              </div>
              <label htmlFor="email">E-mail</label>
              <div>
                <Input
                  name="email"
                  className={`${editable ? 'active' : ''}`}
                  disabled={!editable}
                />
                <button
                  type="button"
                  className={`${editable ? 'active' : ''}`}
                  onClick={() => setEditable(true)}
                >
                  Editar
                </button>
              </div>
              {user.person_type === 'PF' ? (
                <>
                  <label htmlFor="birthday">Data de Nascimento</label>
                  <div>
                    <MaskInput
                      name="birthday"
                      kind="custom"
                      options={{
                        mask: '99/99/9999',
                      }}
                      value={birthdayData}
                      className={`${editable ? 'active' : ''}`}
                      disabled={!editable}
                    />
                    <button
                      type="button"
                      className={`${editable ? 'active' : ''}`}
                      onClick={() => setEditable(true)}
                    >
                      Editar
                    </button>
                  </div>
                  <label htmlFor="sex">Sexo</label>
                  <div>
                    <InputRadio
                      name="sex"
                      options={sexData}
                      className={`${editable ? 'active' : ''}`}
                      disabled={!editable}
                      handleChange={handleChange}
                    />
                    <button
                      type="button"
                      className={`${editable ? 'active' : ''}`}
                      onClick={() => setEditable(true)}
                    >
                      Editar
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <label htmlFor="document">CNPJ</label>
                  <div>
                    <MaskInput
                      name="document"
                      kind="cnpj"
                      value={user.document}
                      className={`${editable ? 'active' : ''}`}
                      disabled={!editable}
                    />
                    <button
                      type="button"
                      className={`${editable ? 'active' : ''}`}
                      onClick={() => setEditable(true)}
                    >
                      Editar
                    </button>
                  </div>
                </>
              )}
            </InputsGroup>
            <InputsGroup>
              {user.person_type === 'PF' ? (
                <>
                  <label htmlFor="rg">RG</label>
                  <div>
                    <MaskInput
                      name="rg"
                      kind="custom"
                      options={{
                        mask: '99.999.999-S',
                      }}
                      value={user.rg}
                      className={`${editable ? 'active' : ''}`}
                      disabled={!editable}
                    />
                    <button
                      type="button"
                      className={`${editable ? 'active' : ''}`}
                      onClick={() => setEditable(true)}
                    >
                      Editar
                    </button>
                  </div>
                  <label htmlFor="document">CPF</label>
                  <div>
                    <MaskInput
                      name="document"
                      kind="cpf"
                      value={user.document}
                      className={`${editable ? 'active' : ''}`}
                      disabled={!editable}
                    />
                    <button
                      type="button"
                      className={`${editable ? 'active' : ''}`}
                      onClick={() => setEditable(true)}
                    >
                      Editar
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <label htmlFor="company_name">Razão Social</label>
                  <div>
                    <Input
                      name="company_name"
                      className={`${editable ? 'active' : ''}`}
                      disabled={!editable}
                    />
                    <button
                      type="button"
                      className={`${editable ? 'active' : ''}`}
                      onClick={() => setEditable(true)}
                    >
                      Editar
                    </button>
                  </div>
                </>
              )}
              <label htmlFor="celphone">Celular</label>
              <div>
                <MaskInput
                  name="celphone"
                  kind="cel-phone"
                  value={user.celphone}
                  className={`${editable ? 'active' : ''}`}
                  disabled={!editable}
                />
                <button
                  type="button"
                  className={`${editable ? 'active' : ''}`}
                  onClick={() => setEditable(true)}
                >
                  Editar
                </button>
              </div>
              <label htmlFor="tellphone">Telefone</label>
              <div>
                <MaskInput
                  name="tellphone"
                  kind="cel-phone"
                  value={user.tellphone}
                  className={`${editable ? 'active' : ''}`}
                  disabled={!editable}
                />
                <button
                  type="button"
                  className={`${editable ? 'active' : ''}`}
                  onClick={() => setEditable(true)}
                >
                  Editar
                </button>
              </div>
            </InputsGroup>
            <InputsGroup w100>
              <label htmlFor="category">Ramo do negócio</label>
              <div>
                <Select
                  name="category"
                  className={`${editable ? 'active' : ''}`}
                  disabled={!editable}
                  options={categories}
                  selected={categorySelected}
                />
                <button
                  type="button"
                  className={`${editable ? 'active' : ''}`}
                  onClick={() => setEditable(true)}
                >
                  Editar
                </button>
              </div>
            </InputsGroup>
          </div>
          <div>
            <button
              type="submit"
              className={`${editable ? 'active' : ''}`}
              onClick={() => setEditable(false)}
            >
              Salvar
            </button>
          </div>
        </Form>
      </FormGroup>
    </Content>
  );
}

export default MeusDados;
