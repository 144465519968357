import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: #f6f7f9;
  min-height: 100vh;
`;

export const HeaderSpacer = styled.div`
  /* width: 100%; */
`;

export const Separator = styled.div`
  width: calc(100% - 150px);
  padding: 20px;

  @media screen and (max-width: 991px) {
    width: 100%;
    margin-bottom: 66px;
  }
`;
