import styled from 'styled-components';
import { shade } from 'polished';

export const RadioButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  input {
    visibility: hidden;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    position: relative;

    :before {
      content: '';
      background-color: transparent;
      border: 2px solid #c5ccd67a;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: visible;
      position: absolute;
      top: 0;
      left: 0;
    }

    :after {
      content: '';
      background-color: transparent;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: visible;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    :checked:after {
      background-color: #323643;
    }
  }

  label {
    line-height: 0;
    color: #c5ccd6;
    font-size: 2rem;
  }

  @media screen and (max-width: 991px) {
    label {
      line-height: normal;
      font-size: 1.8rem;
    }
  }
`;

export const InputsGroup = styled.div`
  padding: 0 10px;
  width: 100%;
  + div {
    padding-top: 15px;
    border-top: 1px solid #c5ccd6;
  }

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;

export const InputGroup = styled.div`
  display: flex;

  > div {
    width: 100%;

    label {
      font-size: 1.5rem;
      color: #c5ccd6;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      input {
        width: 100%;
        border: none;
        height: 40px;
        font-size: 1.5rem;
        color: #323643;
        padding: 0 10px;
      }

      input:disabled {
        background-color: #f2f2f2;
        color: #999;
      }

      input.active {
        border-bottom: 1px solid #323643;
      }

      button {
        background: none;
        border: none;
        color: #ffca18;
        margin: 0 10px;
        padding: 0 10px;
        transition-duration: 0.3s;
        opacity: 1;
        visibility: visible;

        :hover {
          color: ${shade(0.2, '#FFCA18')};
        }
      }

      button.active {
        opacity: 0;
        visibility: hidden;
      }

      > div {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 991px) {
    display: block;

    > div {
      > div {
        button {
          width: auto;
          margin: 0;
        }
      }
    }
  }
`;
