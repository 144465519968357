import React, { useState, useCallback } from 'react';
import { Input as Hidden } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import { Input } from './styles';
import InputComponent from '~/components/Input';

export default function InputRadio({ name, options, handleChange, disabled }) {
  const [value, setValue] = useState('');

  const handleChecked = useCallback(
    e => {
      setValue(e.target.value);
      handleChange(e.target.value);
    },
    [handleChange]
  );

  return (
    <>
      {options.map(option => (
        <Input key={option.value}>
          <input
            type="radio"
            id={option.value}
            name={name}
            value={option.value}
            onChange={handleChecked}
            checked={option.selected}
            disabled={disabled}
          />
          <label htmlFor={option.value}>{option.label}</label>
          <Hidden type="hidden" name={name} value={value} />
          <InputComponent type="hidden" name={name} value={value} />
        </Input>
      ))}
    </>
  );
}

InputRadio.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      selected: PropTypes.bool,
    })
  ).isRequired,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};

InputRadio.defaultProps = {
  handleChange: () => {},
  disabled: false,
};
