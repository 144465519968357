import styled from 'styled-components';
import { lighten, shade } from 'polished';
import { Form as FormComponent } from '@unform/web';

export const Container = styled.div`
  h1 {
    font-size: 4rem;
    color: #ffca18;
    font-weight: 500;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 991px) {
    h1 {
      text-align: center;
    }
  }
`;

export const Content = styled.div``;

export const ServicoImages = styled.div`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

export const ImagemPrincial = styled.div`
  width: 60%;
  padding: 10px;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const ImgPrincipal = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 470px;
  border-radius: 10px;

  @media screen and (max-width: 991px) {
    height: 300px;
  }
`;

export const ImagesGroup = styled.div`
  width: 40%;
  display: ${props => (props.desktop ? 'block' : 'none')};

  @media screen and (max-width: 991px) {
    width: 100%;
    display: ${props => (props.mobile ? 'block' : 'none')};
  }
`;

export const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ImageButton = styled.button`
  padding: 10px;
  width: 50%;
  background: none;
  border: none;

  @media screen and (max-width: 991px) {
    :nth-child(odd) {
      padding-left: 0;
    }

    :nth-child(even) {
      padding-right: 0;
    }
  }
`;

export const Imgs = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 173px;
  border-radius: 10px;
  border: ${props => (props.selected ? '3px solid #173e4f' : 'none')};

  @media screen and (max-width: 991px) {
    height: 130px;
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  button {
    margin: 10px;
    width: 100%;
    height: 30px;
    border-radius: 20px;
    border: none;
    background: #173e4f;
    color: #fff;
    transition-duration: 0.3s;

    :hover {
      background: ${lighten(0.1, '#173E4F')};
    }

    @media screen and (max-width: 991px) {
      :nth-child(odd) {
        margin-left: 0;
      }

      :nth-child(even) {
        margin-right: 0;
      }
    }
  }

  button.btn-tranparent {
    color: #173e4f;
    border: 1px solid #173e4f;
    background-color: transparent;

    :hover {
      color: ${lighten(0.1, '#173E4F')};
      border-color: ${lighten(0.1, '#173E4F')};
      background-color: transparent;
    }
  }
`;

export const ServicoData = styled.div`
  width: 100%;
  padding: 20px 10px;

  strong {
    color: #8c8888;
    font-size: 1.7rem;
  }

  p,
  a {
    color: #c5ccd6;
    font-size: 1.8rem;
  }

  a {
    display: block;
    margin-bottom: 5px;
    transition-duration: 0.3s;

    :hover {
      color: ${shade(0.2, '#c5ccd6')};
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;

    > div {
      width: 50%;
    }
  }

  @media screen and (max-width: 991px) {
    > div {
      display: block;

      > div {
        width: 100%;
      }
    }
  }
`;

export const ComentariosGroup = styled.div`
  padding: 10px;

  h2 {
    color: #8c8888;
    font-size: 1.7rem;
    margin-bottom: 10px;
  }
`;

export const Comentarios = styled.div`
  max-height: 315px;
  overflow-y: auto;
`;

export const Comentario = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;

  img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    margin-right: 10px;
  }

  h3 {
    color: #8c8888;
    font-size: 2rem;
  }

  p {
    color: #c5ccd6;
    font-size: 1.8rem;
  }

  @media screen and (max-width: 991px) {
    img {
      width: 40px;
      height: 40px;
    }

    h3 {
      color: #8c8888;
      font-size: 1.8rem;
    }

    p {
      color: #c5ccd6;
      font-size: 1.5rem;
    }
  }
`;

export const Form = styled(FormComponent)`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 6px #0000002e;
  border-radius: 10px;
  background: #fff;

  textarea {
    border-radius: 10px;
    width: calc(100% - 50px);
    padding: 10px;
    height: 100px;
    resize: none;
    border: none;
    font-size: 2rem;

    ::placeholder {
      color: #8894a2;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    margin: 0 10px;
    transform: rotateZ(-40deg);
    transition-duration: 0.3s;
    background-color: #7b9db7;

    :hover {
      background-color: ${shade(0.1, '#7B9DB7')};
    }
  }

  @media screen and (max-width: 991px) {
    textarea {
      font-size: 1.5rem;
    }
  }
`;
