import styled from 'styled-components';

export const Content = styled.div`
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  padding: 0 10px 10px;

  h2 {
    padding: 15px 25px 0;
    font-size: 3.5rem;
    font-weight: 500;
    color: #1d2433;
  }

  @media screen and (max-width: 991px) {
    h2 {
      text-align: center;
      font-size: 3rem;
    }
  }
`;

export const CategoriasList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Categoria = styled.div`
  padding: 10px;
  width: 25%;
  height: 150px;

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 17px;
    background-color: #d17777;
    width: 100%;
    height: 100%;
    transition-duration: 0.3s;
    padding: 0 10px;
    text-align: center;

    h3 {
      color: #ffffff;
      font-weight: 600;
      font-size: 2rem;
    }

    :hover {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const NotFound = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    padding: 0 10px;
    font-size: 2rem;
    color: #707070;
    text-align: center;
  }
`;
