import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div``;

export const HeaderPerfil = styled.div`
  display: flex;

  > div {
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-right: 15px;
    }

    h1 {
      font-size: 4rem;
      color: #173e4f;
      font-weight: 500;
      margin-bottom: 0px;
    }

    > p {
      color: #9da3b4;
      font-size: 1.8rem;
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: 991px) {
    display: block;
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;

  @media screen and (max-width: 991px) {
    display: block;
  }
`;

export const Status = styled.div`
  width: 50%;
  padding: 10px 15px;

  + div {
    border-left: 1px solid #173e4f;
  }

  h2 {
    color: #173e4f;
    font-size: 2.5rem;
    font-weight: 600;
  }

  h3 {
    color: #8c8888;
    font-size: 2rem;
    font-weight: 600;
  }

  p {
    color: #c5ccd6;
    font-size: 1.8rem;

    + h3 {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 991px) {
    width: 100%;

    + div {
      border-top: 1px solid #173e4f;
      border-left: 0;
    }
  }
`;

export const Servicos = styled.div`
  border-left: 0 !important;
  width: 100%;

  h2 {
    padding: 0 20px;
    font-size: 4rem;
    color: #173e4f;
    font-weight: 500;
    margin-top: 10px;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 3.5rem;
      text-align: center;
    }
  }
`;

export const Servico = styled.div`
  padding: 20px;
  width: 25%;

  > div {
    border-radius: 17px;
    box-shadow: 0px 2.5px 4px 0px #aaa;

    div {
      padding: 10px;

      h3 {
        color: #8c8888;
        font-weight: 600;
        font-size: 1.8rem;
      }

      p {
        color: #c5ccd6;
        font-weight: 500;
        font-size: 1.3rem;
      }

      a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #173e4f;
        border: 1px solid #173e4f;
        color: #fff;
        border-radius: 50px;
        height: 30px;
        transition-duration: 0.3s;
        margin-top: 10px;

        :hover {
          border-color: ${lighten(0.1, '#173E4F')};
          background-color: ${lighten(0.1, '#173E4F')};
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const Img = styled.div`
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 17px 17px 0px 0px;
  width: 100%;
  height: 198px;
`;
