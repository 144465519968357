import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';

import { Container, Content, CloseButton } from './styles';

export default function Modal({ opened, close, children, className }) {
  useEffect(() => {
    document.addEventListener('click', evt => {
      const modais = document.getElementsByClassName('modal');
      const modalButtons = document.getElementsByClassName('modalButton');
      const targetElement = evt.target;

      // eslint-disable-next-line no-restricted-syntax
      for (const modalButton of modalButtons) {
        let targetElement2 = targetElement;
        do {
          if (targetElement2 === modalButton) {
            return;
          }
          targetElement2 = targetElement2.parentNode;
        } while (targetElement2);
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const modal of modais) {
        let targetElement2 = targetElement;
        do {
          if (targetElement2 === modal) {
            return;
          }
          targetElement2 = targetElement2.parentNode;
        } while (targetElement2);
      }

      if (
        typeof targetElement.className === 'string' &&
        targetElement.className.match(/ css-.*/)
      ) {
        return;
      }

      close();
    });
  }, [close]);

  return (
    <Container opened={opened}>
      <Content className={`modal ${className}`}>
        <CloseButton onClick={close}>
          <FaTimes />
        </CloseButton>
        {children}
      </Content>
    </Container>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  close: PropTypes.func.isRequired,
  className: PropTypes.string,
  opened: PropTypes.bool,
};

Modal.defaultProps = {
  className: '',
  opened: false,
};
