import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import api from '~/services/api';

import { Content, ServicosList, Servico, Img, Vazio } from './styles';

export default function Servicos() {
  const id = useSelector(state => state.auth.user.id);
  const [services, setService] = useState([]);

  useEffect(() => {
    api.get(`/services/user/${id}`).then(response => {
      const data = response.data.map(service => ({
        id: service.id,
        image: service.files[0].url,
        name: service.name,
        description: service.description,
        slug: service.slug,
      }));
      setService(data);
    });
  }, [id]);
  return (
    <Content>
      <h2>Meus Serviços</h2>
      <ServicosList>
        {services.length > 0 ? (
          <>
            {services.map(service => (
              <Servico key={service.id}>
                <div>
                  <Img src={service.image} />
                  <div>
                    <h3>{service.name}</h3>
                    <p>{service.description}</p>
                    <Link to={`/perfil/meus-servicos/${service.slug}`}>
                      Editar
                    </Link>
                  </div>
                </div>
              </Servico>
            ))}
          </>
        ) : (
          <Vazio>
            <strong>Nenhum serviço cadastrado.</strong>
          </Vazio>
        )}
      </ServicosList>
    </Content>
  );
}
