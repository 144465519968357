import styled from 'styled-components';

export const Input = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px 0;

  input {
    visibility: hidden;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    position: relative;

    :before {
      content: '';
      background-color: transparent;
      border: 1px solid #c5ccd67a;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: visible;
      position: absolute;
      top: 0;
      left: 0;
    }

    :after {
      content: '';
      background-color: transparent;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: visible;
      position: absolute;
      top: 46%;
      left: 44%;
      transform: translate(-50%, -50%);
    }

    :checked:after {
      background-color: #323643;
    }
  }

  label {
    margin: 0 !important;
  }
`;
