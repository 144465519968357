import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import api from '~/services/api';

import {
  LogoMobile,
  Destaques,
  Destaque,
  Categorias,
  Categoria,
} from './styles';
import Container from '~/components/Container';

import Logo from '~/assets/logos/logo-completo.svg';

export default function HomeLogado() {
  const [providers, setProviders] = useState([]);
  const [clients, setClients] = useState([]);
  const [categorias, setCategorias] = useState([]);
  useEffect(() => {
    api.get('provider-reviews').then(response => {
      const data = response.data.map(provider => ({
        id: provider.user.id,
        name: provider.user.name,
        slug: provider.user.slug,
        category: provider.user.category && provider.user.category.name,
        avatar: provider.user.avatar.url,
      }));

      setProviders(data);
    });

    api.get('client-reviews').then(response => {
      const data = response.data.map(client => ({
        id: client.user.id,
        name: client.user.name,
        slug: client.user.slug,
        avatar: client.user.avatar.url,
      }));

      setClients(data);
    });

    api.get('/categories').then(response => {
      const data = response.data.map(category => ({
        slug: category.name.replace(/ /g, '-').toLowerCase(),
        ...category,
      }));
      setCategorias(data);
    });
  }, []);

  // const responsive = {
  //   unique: {
  //     breakpoint: { max: 3000, min: 0 },
  //     items: 1,
  //   },
  // };

  return (
    <>
      <LogoMobile>
        <img src={Logo} alt="Logo do Wodo" />
      </LogoMobile>
      {/* <Carousel responsive={responsive}>
        <Container>
          <Content>
            <p>Teste</p>
          </Content>
        </Container>
        <Container>
          <Content>
            <p>Teste</p>
          </Content>
        </Container>
        <Container>
          <Content>
            <p>Teste</p>
          </Content>
        </Container>
        <Container>
          <Content>
            <p>Teste</p>
          </Content>
        </Container>
      </Carousel> */}

      <Container>
        <Destaques>
          <h2>Prestadores em destaques:</h2>
          {providers.length > 0 ? (
            <div>
              {providers.map(provider => (
                <Destaque key={provider.id}>
                  <Link to={`/perfil/${provider.slug}`}>
                    <img src={provider.avatar} alt={provider.name} />
                    <div>
                      <h3>{provider.name}</h3>
                      <p>{provider.category}</p>
                    </div>
                  </Link>
                </Destaque>
              ))}
            </div>
          ) : (
            <p>Desculpem-nos ainda não temos prestadores em destaque.</p>
          )}
        </Destaques>
      </Container>

      <Container>
        <Destaques>
          <h2>Clientes em destaques:</h2>
          {clients.length > 0 ? (
            <div>
              {clients.map(client => (
                <Destaque key={client.id}>
                  <Link to={`/perfil/${client.slug}`}>
                    <img src={client.avatar} alt={client.name} />
                    <div>
                      <h3>{client.name}</h3>
                    </div>
                  </Link>
                </Destaque>
              ))}
            </div>
          ) : (
            <p>Desculpem-nos ainda não temos cliente em destaque.</p>
          )}
        </Destaques>
      </Container>

      <Container>
        <Categorias>
          <h2>Categorias:</h2>

          {categorias.length > 0 ? (
            <div>
              {categorias.map(categoria => (
                <Categoria key={categoria.id}>
                  <Link to={`/servicos/categorias/${categoria.slug}`}>
                    <h3>{categoria.name}</h3>
                  </Link>
                </Categoria>
              ))}
            </div>
          ) : (
            <p>Desculpem-nos ainda não temos categorias cadastradas.</p>
          )}
        </Categorias>
      </Container>
    </>
  );
}
