import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { IoMdTrash } from 'react-icons/io';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

import api from '~/services/api';
import history from '~/services/history';

import {
  Content,
  Form,
  Timeline,
  PassoGroup,
  Passo,
  InputGroup,
  RadioGroup,
  ButtonNewAddress,
  InputFileGroup,
  FilePreview,
  DropzoneContainer,
  ButtonGroup,
  Title,
  ButtonModal,
} from './styles';
import Input from '~/components/Input';
import Modal from '~/components/Modal';
import InputMask from '~/components/InputMask';
import MaskInput from '~/components/MaskInput';
import Select from '~/components/Select';

export default function EditarServico() {
  const user = useSelector(state => state.auth.user);
  const params = useParams();
  const [passo, setPasso] = useState(1);
  const [passoFinal, setPassoFinal] = useState(false);
  const [opened, setOpened] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [addressSelected, setAddressSelected] = useState(0);
  // const [filesOld, setFilesOld] = useState([]);
  const [files, setFiles] = useState([]);
  const [service, setService] = useState({});

  useEffect(() => {
    const { slug } = params;
    api.get(`services/${slug}`).then(response => {
      setFiles(response.data.files);
      // setFilesOld(response.data.files);
      const data = {
        id: response.data.id,
        title: response.data.name,
        price: response.data.value,
        initial_hour: response.data.initial_hour,
        end_hour: response.data.end_hour,
        description: response.data.description,
      };
      setService(data);
      setAddressData(response.data.id_address);
    });
  }, [params]);

  useEffect(() => {
    api.get(`adresses/user/${user.id}`).then(response => {
      const data = response.data.map(address => {
        if (address.is_main === '1') {
          setAddressSelected(address.id);
        }
        return {
          id: address.id,
          cep: `${address.cep.substr(0, 5)}-${address.cep.substr(5, 8)}`,
          city: address.city,
          complement: address.complement,
          is_main: address.is_main,
          neighborhood: address.neighborhood,
          number: address.number,
          state: address.state,
          street: address.street,
        };
      });
      setAddresses(data);
    });
  }, [user.id]);

  const handleBeforePasso = useCallback(() => {
    setPassoFinal(false);
    setPasso(state => state - 1);
  }, []);

  const handleNextPasso = useCallback(() => {
    setPassoFinal(passo === 2);
    setPasso(state => state + 1);
  }, [passo]);

  const handleInsertCep = useCallback(async e => {
    const zipcode = e.target.value.replace(/\D/g, '');
    if (zipcode.length === 8) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${zipcode}/json/`
      );
      const data = {
        cep: zipcode,
        street: response.data.logradouro,
        neighborhood: response.data.bairro,
        city: response.data.localidade,
        state: response.data.uf,
        complement: response.data.complemento,
      };
      setAddressData(data);
    }
  }, []);

  const handleSaveAdress = useCallback(
    async ({ cep, street, number, neighborhood, city, state, complement }) => {
      const response = await api.post('adresses', {
        id_user: user.id,
        street,
        number,
        neighborhood,
        city,
        state,
        cep: cep.replace(/\D/g, ''),
        complement,
      });
      setAddresses(oldState => [...oldState, response.data]);
      setAddressSelected(response.data.id);
      setOpened(false);
    },
    [user.id]
  );

  const states = useMemo(() => {
    return [
      { value: 'AC', label: 'Acre' },
      { value: 'AL', label: 'Alagoas' },
      { value: 'AP', label: 'Amapá' },
      { value: 'AM', label: 'Amazonas' },
      { value: 'BA', label: 'Bahia' },
      { value: 'CE', label: 'Ceará' },
      { value: 'DF', label: 'Distrito Federal' },
      { value: 'ES', label: 'Espirito Santo' },
      { value: 'GO', label: 'Goiás' },
      { value: 'MA', label: 'Maranhão' },
      { value: 'MS', label: 'Mato Grosso do Sul' },
      { value: 'MT', label: 'Mato Grosso' },
      { value: 'MG', label: 'Minas Gerais' },
      { value: 'PA', label: 'Pará' },
      { value: 'PB', label: 'Paraíba' },
      { value: 'PR', label: 'Paraná' },
      { value: 'PE', label: 'Pernambuco' },
      { value: 'PI', label: 'Piauí' },
      { value: 'RJ', label: 'Rio de Janeiro' },
      { value: 'RN', label: 'Rio Grande do Norte' },
      { value: 'RS', label: 'Rio Grande do Sul' },
      { value: 'RO', label: 'Rondônia' },
      { value: 'RR', label: 'Roraima' },
      { value: 'SC', label: 'Santa Catarina' },
      { value: 'SP', label: 'São Paulo' },
      { value: 'SE', label: 'Sergipe' },
      { value: 'TO', label: 'Tocantins' },
    ];
  }, []);

  const handleDrop = useCallback(
    data => {
      if (files.length <= 8) {
        const fileData = data.map(file => {
          return {
            file,
            url: URL.createObjectURL(file),
          };
        });
        setFiles(state => [...state, ...fileData]);
      }
    },
    [files]
  );

  const removeFile = useCallback(
    async url => {
      const fileSelected = files.find(file => file.url === url);
      const newFiles = files.filter(file => file.url !== url);
      api.delete(`files/${fileSelected.id}`);
      setFiles(newFiles);
    },
    [files]
  );

  const handleClickSave = useCallback(() => {
    const submitButton = document.getElementById('submitButton');
    submitButton.click();
  }, []);

  const handleSubmit = useCallback(
    async ({ title, price, initial_hour, end_hour, description }) => {
      const id_address = addressSelected;

      if (id_address) {
        const reponseService = await api.put(`services/${service.id}`, {
          id_user: user.id,
          id_address,
          id_category: user.id_category,
          name: title,
          description,
          value: price
            .replace('R$', '')
            .replace(',', '.')
            .replace('.', ''),
          initial_hour,
          end_hour,
          slug: `${title.replace(' ', '-')}-${user.id}${id_address}${
            user.id_category
          }`,
        });
        if (reponseService.data) {
          const fileData = new FormData();
          fileData.append('id_service', reponseService.data.id);

          files.map(file => fileData.append('files', file.file));

          const response = await api.post('files', fileData);

          if (response.status === 200) {
            Swal.fire(
              'Servico salvo!',
              'Serviço editado com sucesso.',
              'success'
            ).then(_ => {
              history.push('/perfil/meus-servicos');
            });
          }
        }
      }
    },
    [addressSelected, files, service.id, user.id, user.id_category]
  );

  return (
    <>
      <Content>
        <div>
          <h1>Cadastrar Serviço</h1>
          <h2>Insira os dados para cadastrar um novo serviço</h2>
        </div>
        <Form initialData={service} id="form" onSubmit={handleSubmit}>
          <Timeline passo={passo}>
            <div>
              <div>
                <span>1</span>
              </div>
              <div className="separator" />
              <div>
                <span>2</span>
              </div>
              <div className="separator" />
              <div>
                <span>3</span>
              </div>
            </div>
            {passo === 1 && <h3>Dados do serviço</h3>}
            {passo === 2 && <h3>Endereço</h3>}
            {passo === 3 && <h3>Fotos</h3>}
          </Timeline>
          <PassoGroup>
            <Passo active={passo === 1}>
              <InputGroup>
                <div>
                  <label htmlFor="title">Título</label>
                  <Input name="title" />
                </div>
                <div>
                  <label htmlFor="price">Price</label>
                  <MaskInput
                    name="price"
                    placeholder="R$"
                    kind="money"
                    options={{
                      precision: 2,
                      separator: ',',
                      delimiter: '.',
                      unit: 'R$',
                      suffixUnit: '',
                    }}
                    value={service.price}
                  />
                </div>
              </InputGroup>
              <InputGroup>
                <div>
                  <label>Horário de Atendimento</label>
                  <div>
                    <MaskInput
                      name="initial_hour"
                      placeholder="08:00"
                      kind="custom"
                      options={{
                        mask: '99:99',
                      }}
                      value={service.initial_hour}
                    />
                    <label>Até</label>
                    <MaskInput
                      name="end_hour"
                      placeholder="18:00"
                      kind="custom"
                      options={{
                        mask: '99:99',
                      }}
                      value={service.end_hour}
                    />
                  </div>
                </div>
              </InputGroup>
              <InputGroup>
                <div>
                  <label htmlFor="Titulo">Descrição</label>
                  <Input name="description" multiline />
                </div>
              </InputGroup>
            </Passo>
            <Passo active={passo === 2}>
              {addresses.map(address => (
                <RadioGroup>
                  <div>
                    <input
                      id={`end_${address.id}`}
                      type="radio"
                      name="address"
                      checked={addressSelected === address.id}
                      onChange={() => setAddressSelected(address.id)}
                    />
                  </div>
                  <div>
                    <label htmlFor={`end_${address.id}`}>
                      <h3>
                        Usar{' '}
                        {address.is_main === '1'
                          ? 'endereço principal'
                          : 'este endereço'}
                      </h3>
                      <p>
                        {address.street}, {address.number}
                        {address.complement
                          ? `, ${address.complement}`
                          : ''} - {address.neighborhood}, {address.city} -{' '}
                        {address.state}, <br />
                        {address.cep}
                      </p>
                    </label>
                  </div>
                </RadioGroup>
              ))}
              <ButtonNewAddress
                type="button"
                className="modalButton"
                onClick={() => setOpened(true)}
              >
                <b>+</b>
                <p>Adicionar um novo endereço</p>
              </ButtonNewAddress>
            </Passo>
            <Passo active={passo === 3}>
              <InputFileGroup>
                {files.map(file => (
                  <FilePreview key={file.url} file={file.url}>
                    <button type="button" onClick={() => removeFile(file.url)}>
                      <IoMdTrash size={60} color="#f00" />
                    </button>
                  </FilePreview>
                ))}
                {files.length <= 8 && (
                  <DropzoneContainer>
                    <Dropzone onDrop={handleDrop}>
                      {({ getRootProps, getInputProps }) => (
                        <label id="imagens">
                          <div {...getRootProps()}>
                            <Input
                              name="imagens"
                              {...getInputProps()}
                              accept="image/*"
                            />
                            <p>+ Adicionar</p>
                          </div>
                        </label>
                      )}
                    </Dropzone>
                  </DropzoneContainer>
                )}
              </InputFileGroup>
            </Passo>
          </PassoGroup>
          <ButtonGroup passo={passo}>
            {passo !== 1 && (
              <button type="button" onClick={handleBeforePasso}>
                Voltar
              </button>
            )}
            {!passoFinal ? (
              <button type="button" onClick={handleNextPasso}>
                Próximo
              </button>
            ) : (
              <>
                <button type="button" onClick={handleClickSave}>
                  Salvar
                </button>
                <button id="submitButton" type="submit" className="d-none" />
              </>
            )}
          </ButtonGroup>
        </Form>
      </Content>
      <Modal opened={opened} close={() => setOpened(false)}>
        <Title>Cadastrar Novo Endereço</Title>
        <Form onSubmit={handleSaveAdress} initialData={addressData} hauto={1}>
          <InputGroup>
            <div>
              <label htmlFor="cep">CEP</label>
              <InputMask
                mask="99999-999"
                name="cep"
                onChange={handleInsertCep}
              />
            </div>
          </InputGroup>
          <InputGroup>
            <div>
              <label htmlFor="street">Rua</label>
              <Input name="street" />
            </div>
          </InputGroup>
          <InputGroup>
            <div>
              <label htmlFor="number">Número</label>
              <Input name="number" />
            </div>
            <div>
              <label htmlFor="neighborhood">Bairro</label>
              <Input name="neighborhood" />
            </div>
          </InputGroup>
          <InputGroup>
            <div>
              <label htmlFor="city">Cidade</label>
              <Input name="city" />
            </div>
            <div>
              <label htmlFor="state">Estado</label>
              <Select name="state" options={states} />
            </div>
          </InputGroup>
          <InputGroup>
            <div>
              <label htmlFor="complement">Complemento</label>
              <Input name="complement" />
            </div>
          </InputGroup>
          <ButtonModal>
            <button type="submit">Salvar</button>
          </ButtonModal>
        </Form>
      </Modal>
    </>
  );
}
