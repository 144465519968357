import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import 'react-multi-carousel/lib/styles.css';

export default createGlobalStyle`

  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;700;900&display=swap');

  ::-webkit-scrollbar{
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #173e4f;
  }

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus{
    outline: 0;
  }

  html{
    /* 1rem é igual a 10px */
    /* font-size: 62.5%; */
    font-size: 56%;
  }

  .swal2-popup.swal2-modal.swal2-show {
    font-size: 1.4rem;
  }

  body{
    -webkit-font-smoothing: antialiased;
    color: #303030;
  }

  body, input, textarea, button{
    font-size: 1.4rem;
    font-family: 'Poppins', sans-serif;
  }

  a{
    text-decoration: none;
  }

  ul{
    list-style: none;
  }

  button{
    cursor: pointer;
  }

  .react-datepicker {
    font-size: 1.3rem !important;
  }

  .react-datepicker__current-month {
    font-size: 1.5rem !important;
  }

  .react-datepicker__header {
    padding-top: 6px !important;
  }

  .react-datepicker__navigation {
    top: 13px !important;
  }

  .react-datepicker__day-name, .react-datepicker__day {
    margin: 0.5rem !important;
  }

  @media screen and (max-width: 1024px) {
    html{
      font-size: 55%;
    }
  }

  @media screen and (max-width: 768px) {
    html{
      font-size: 48%;
    }
  }

  .loader{
    height:100vh;
    width:100%;
    background:#1441f7;
    display:flex;
    align-items:center;
    justify-content:center;
  }

  .loader svg{
    -webkit-animation:spin 1.5s linear infinite;
    -moz-animation:spin 1.5s linear infinite;
    animation:spin 1.5s linear infinite;
  }

  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

  .cookies{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border: 2px solid  #1441f7;
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 200px;
    height: 80px;
    border-radius: 10px;
  }

  .cookies p{
    color: #1441f7;
    font-weight: bold;
    margin-top: 10px;
  }

  .cookies button{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1441f7;
    color: #fff;
    border: 0;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 200px;
    height: 40px;
    padding: 10px;
    transition-duration: 0.2s;
    font-weight: bold;
  }
`;
