import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import api from '~/services/api';

import { Content } from './styles';

import Logo from '~/assets/logos/logo.svg';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Digite um e-mail valido')
    .required('O e-mail é obrigatório'),
});

export default function EsqueciSenha() {
  const handleSubmit = useCallback(async ({ email }) => {
    try {
      await api.post('forgotPassword', {
        email,
      });
      Swal.fire(
        'solicitação enviada!',
        'Um e-mail foi enviado para prosseguir com a troca de senha.',
        'success'
      );
    } catch (e) {
      Swal.fire(
        'Opsss...',
        'Ocorreu um erro ao fazer a solicitação, <br>tente novamente.',
        'error'
      );
    }
  }, []);

  return (
    <Content>
      <div>
        <div>
          <div>
            <div>
              <img src={Logo} alt="" />
            </div>
            <div>
              <h1>Esqueci minha senha</h1>
              <h2>
                Para recuperar a senha basta <br />
                escrever seu E-mail abaixo.
              </h2>
            </div>
          </div>
          <Form schema={schema} onSubmit={handleSubmit}>
            <label htmlFor="email">E-mail</label>
            <Input
              id="email"
              type="email"
              name="email"
              placeholder="example@example.com"
            />
            <button type="submit">Enviar</button>
            <div>
              <Link to="/">Voltar</Link>
            </div>
          </Form>
        </div>
      </div>
      <div />
    </Content>
  );
}
