import styled from 'styled-components';
import { darken } from 'polished';

import bgLogin from '~/assets/defaults/bg-login.svg';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;

  span {
    font-size: 1.2rem;
    color: #ff3333;
    margin-top: -15px;
    display: block;
  }

  > div {
    :nth-child(1) {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      > div:nth-child(1) {
        width: 50%;
        padding: 0 15px;
        margin: 0 auto;

        > div {
          text-align: center;
          margin-bottom: 25px;

          div {
            :nth-child(1) {
              padding: 0 15px;

              img {
                width: 30%;
              }
            }

            h1 {
              color: #1d2433;
              font-size: 3rem;
              font-weight: 600;
            }

            h2 {
              font-size: 1.7rem;
              color: #9da3b4;
              font-weight: 500;
            }
          }
        }

        form {
          width: 100%;

          label {
            display: block;
            width: 100%;
            color: #b7c5d8;
            font-size: 1.5rem;

            + label {
              display: flex;
              border-bottom: 1px solid #d8d8d8;
              margin-bottom: 20px;

              > input {
                border: none;
                margin-bottom: 0;
              }
              button {
                border: none;
                background: none;
                svg {
                  transition: color 0.3s;
                }

                :hover {
                  svg {
                    color: ${darken('0.05', '#C6CBD4')} !important;
                  }
                }
              }
            }
          }

          input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #d8d8d8;
            padding: 5px;
            margin-bottom: 20px;
            ::placeholder {
              color: #c8cfd8;
            }
          }

          a {
            color: #ffca18;
            font-weight: 700;
            transition: color 0.3s;
            :hover {
              color: ${darken(0.1, '#ffca18')};
            }
          }

          > button {
            margin: 20px 0;
            width: 100%;
            border-radius: 50px;
            background: #173e4f;
            border: none;
            color: #fff;
            height: 35px;
            transition: background 0.3s;
            :hover {
              background: ${darken(0.05, '#173E4F')};
            }
          }

          div {
            display: flex;

            p {
              color: #c8cfd8;
              margin-right: 10px;
            }
          }
        }
      }
    }

    :nth-child(2) {
      width: 50%;
      background: url(${bgLogin});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  @media screen and (max-width: 991px) {
    > div {
      :nth-child(1) {
        width: 100%;

        > div:nth-child(1) {
          width: 100%;
        }
      }

      :nth-child(2) {
        display: none;
      }
    }
  }
`;
