import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Form as FormComponent } from '@unform/web';
import ModalComponent from '~/components/Modal';

export const Container = styled.div`
  h1 {
    font-size: 4rem;
    color: #173e4f;
    font-weight: 500;
    margin-bottom: 0px;
  }

  > p {
    color: #9da3b4;
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  padding: 0 10px;
`;

export const Servicos = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (max-width: 991px) {
    padding: 10px 0;
  }
`;

export const Servico = styled.div`
  padding: 20px;
  width: 25%;

  > div {
    border-radius: 17px;
    box-shadow: 0px 2.5px 4px 0px #aaa;
    height: 333px;

    > div {
      padding: 10px;

      h3 {
        color: #8c8888;
        font-weight: 600;
        font-size: 1.8rem;
      }

      p {
        color: #c5ccd6;
        font-weight: 500;
        font-size: 1.3rem;
      }
    }
  }

  @media screen and (max-width: 991px) {
    padding: 10px;
    width: 100%;
  }
`;

export const Img = styled.div`
  border-radius: 17px 17px 0px 0px;
  height: 198px;
  width: 100%;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const StatusGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.provider ? 'space-between' : 'flex-end')};
  flex-wrap: wrap;
`;

export const Status = styled.strong`
  margin-top: auto 0;
  width: 100%;
  color: ${props => {
    let cor;
    switch (props.status) {
      case 0:
        cor = '#8BB0BF';
        break;
      case 1:
        cor = '#FFCA18';
        break;
      case 2:
        cor = '#67AA0E';
        break;
      case 3:
        cor = '#F3534A';
        break;
      case 4:
        cor = '#F3994A';
        break;
      default:
        cor = '#8BB0BF';
    }

    return cor;
  }};
`;

export const StatusButton = styled.button`
  width: 49%;
  display: ${props =>
    (props.status === 0 && props.provider) ||
    props.status === 4 ||
    (props.status === 2 && props.providerReview && !props.provider) ||
    (props.status === 2 && props.clientReview && props.provider)
      ? 'none'
      : 'flex'};
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50px;
  height: 30px;
  transition-duration: 0.3s;
  border-width: 1px;
  border-style: solid;
  margin-top: 10px;
  font-size: 1.3rem;

  ${props => {
    let color;
    switch (props.status) {
      case 0:
        color = '#8BB0BF';
        break;
      case 1:
        color = '#F3534A';
        break;
      case 2:
        color = '#67AA0E';
        break;
      case 3:
        color = '#F3534A';
        break;
      case 4:
        color = '#F3994A';
        break;
      default:
        color = '#8BB0BF';
    }

    return css`
      border: 1px solid ${color};
      color: ${color};

      :hover {
        border-color: ${shade(0.1, color)};
        color: ${shade(0.1, color)};
      }
    `;
  }}
`;

export const OtherStatusButton = styled.button`
  display: ${props => (props.provider ? 'flex' : 'none')};
  width: 49%;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50px;
  height: 30px;
  transition-duration: 0.3s;
  border-width: 1px;
  border-style: solid;
  margin-top: 10px;
  border: 1px solid #67aa0e;
  color: #67aa0e;
  font-size: 1.3rem;

  :hover {
    border-color: ${shade(0.1, '#67AA0E')};
    color: ${shade(0.1, '#67AA0E')};
  }
`;

export const Modal = styled(ModalComponent)`
  padding: 30px;
  min-width: 250px;

  hr {
    margin-top: 5px;
    background: #9da3b4;
    width: 100%;
    height: 1px;
    border: none;
  }

  p {
    margin: 10px 0;
  }
`;

export const Form = styled(FormComponent)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  label {
    margin: 10px 0 5px;
    width: 100%;
  }

  textarea {
    width: 100%;
    border-radius: 5px;
    min-height: 100px;
    max-height: 200px;
    resize: vertical;
    margin-bottom: 5px;
    padding: 10px;
  }

  button {
    background: #8bb0bf;
    color: #fff;
    transition-duration: 0.3s;
    border: none;
    border-radius: 50px;
    height: 30px;
    padding: 0 10px;
    width: 45%;

    :hover {
      background: ${shade(0.1, '#8BB0BF')};
    }
  }

  button.cancelar,
  button.fechar {
    background: #f3534a;

    :hover {
      background: ${shade(0.1, '#F3534A')};
    }
  }

  button.concluir,
  button.avaliar {
    background: #67aa0e;

    :hover {
      background: ${shade(0.1, '#67AA0E')};
    }
  }
`;

export const RadioGroup = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  div {
    width: 20%;
    input {
      width: 36px;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 5px;

  button {
    background: #8bb0bf;
    color: #fff;
    transition-duration: 0.3s;
    border: none;
    border-radius: 50px;
    height: 30px;
    padding: 0 10px;
    width: 45%;

    :hover {
      background: ${shade(0.1, '#8BB0BF')};
    }
  }

  button.cancelar,
  button.fechar {
    background: #f3534a;

    :hover {
      background: ${shade(0.1, '#F3534A')};
    }
  }

  button.concluir,
  button.avaliar {
    background: #67aa0e;

    :hover {
      background: ${shade(0.1, '#67AA0E')};
    }
  }
`;
