import styled from 'styled-components';
import { Form as FormComponent } from '@unform/web';
import { shade, lighten } from 'polished';
import ReactChatView from 'react-chatview';

export const Container = styled.div`
  h1 {
    font-size: 4rem;
    color: #173e4f;
    font-weight: 500;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 991px) {
    h1.hidden {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;

  @media screen and (max-width: 991px) {
    display: block;
    margin-top: 10px;
  }
`;

export const Chats = styled.div`
  width: 35%;
  height: 75vh;
  overflow-y: hidden;
  padding: 10px;

  @media screen and (max-width: 991px) {
    width: 100%;
    height: 70vh;
    display: ${props => (props.hiddenMobile ? 'none' : 'block')};
  }
`;

export const Search = styled.div`
  background: #fff;
  height: 40px;
  padding: 10px 20px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  border: 0.5px solid #0000000d;
  box-shadow: 0px 5px 6px #0000000d;

  input {
    margin: 0 10px;
    border: none;
    background: none;
    width: 100%;
    font-size: 1.8rem;

    ::placeholder {
      color: #c5ccd6;
    }
  }
`;

export const Users = styled(ReactChatView)`
  padding: 10px;
  overflow-y: auto;
  height: calc(100% - 50px);

  ::-webkit-scrollbar-thumb {
    background: #173e4f4d;
  }
`;

export const User = styled.button`
  border: none;
  background: none;
  display: flex;
  width: 100%;
  transition-duration: 0.3s;
  max-height: 75px;
  overflow: hidden;

  img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
  }

  div {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;

    h3 {
      color: #8c8888;
      font-size: 2rem;
    }

    p {
      font-size: 2rem;
      color: #c5ccd6;

      small {
        font-size: 1.5rem;
      }
    }

    :nth-child(3) {
      justify-content: flex-start;
      margin-left: auto;
    }
  }

  + button {
    margin-top: 15px;
  }

  :hover {
    opacity: 0.7;
  }

  @media screen and (max-width: 991px) {
    img {
      width: 40px;
      height: 40px;
    }

    div {
      padding: 0 5px;

      h3 {
        font-size: 1.7rem;
      }

      p {
        font-size: 1.6rem;

        small {
          font-size: 1.3rem;
        }
      }
    }
  }
`;

export const ChatGroup = styled.div`
  margin-left: 10px;
  width: 65%;
  border-left: 1px solid #e1e3e8;
  background-color: #e5edf0;

  @media screen and (max-width: 991px) {
    margin: 0;
    border: 0;
    width: 100%;
    display: ${props => (props.showMobile ? 'block' : 'none')};
  }
`;

export const HeaderChat = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 5px 10px #0000000d;
  padding: 10px;
  background-color: #fff;

  > div {
    display: flex;
    align-items: center;
    position: relative;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    h2 {
      margin-left: 20px;
      color: #8c8888;
    }

    button {
      background: none;
      border: none;
      transition-duration: 0.3s;
      margin-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      :hover {
        opacity: 0.7;
      }
    }

    > div {
      background: #fff;
      border-radius: 10px 0 10px 10px;
      box-shadow: 0px 2px 5px 1px #00000033;
      position: absolute;
      top: 25px;
      right: 25px;
      width: 500%;
      padding: 10px;

      li + li {
        margin-top: 10px;
      }
    }
  }

  button.arrow {
    display: none;
  }

  @media screen and (max-width: 991px) {
    > div {
      img {
        width: 40px;
        height: 40px;
      }

      h2 {
        margin-left: 15px;
        font-size: 1.8rem;
      }

      button {
        background: none;
        border: none;
        transition-duration: 0.3s;
        margin-right: 15px;

        :hover {
          opacity: 0.7;
        }
      }

      > div {
        background: #fff;
        border-radius: 10px 0 10px 10px;
        box-shadow: 0px 2px 5px 1px #00000033;
        position: absolute;
        top: 25px;
        right: 25px;
        width: 500%;
        padding: 10px;

        li + li {
          margin-top: 10px;
        }
      }
    }

    button.arrow {
      display: block;
    }
  }
`;

export const ProposalOptions = styled.div`
  transition-duration: 0.15s;
  visibility: ${props => (props.opened ? 'visible' : 'hidden')};
  opacity: ${props => (props.opened ? '1' : '0')};
`;

export const Chat = styled(ReactChatView)`
  height: 48vh;
  overflow-y: auto;

  ::-webkit-scrollbar-thumb {
    background: #173e4f4d;
  }

  > div {
    padding: 10px;
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      margin-top: 2px;

      div.me {
        margin-left: auto;
        justify-self: flex-end;
        max-width: 60%;
        background-color: #173e4f;
        box-shadow: 0px 4px 35px #4f7cfe1a;
        padding: 10px 20px;
        border-radius: 20px 0 20px 20px;
        color: #fff;
      }

      div.you {
        justify-self: flex-start;
        max-width: 60%;
        background-color: #fff;
        box-shadow: 0px 4px 35px #0000001a;
        padding: 10px 20px;
        border-radius: 0 20px 20px 20px;
        color: #8894a2;
      }
    }

    div.meContent + div.youContent,
    div.youContent + div.meContent {
      margin-top: 10px;
    }

    div.meContent + div.meContent {
      div.me {
        border-radius: 20px;
      }
    }

    div.youContent + div.youContent {
      div.you {
        border-radius: 20px;
      }
    }
  }
`;

export const GroupButtons = styled.div`
  display: flex;
  justify-content: space-around;

  button {
    width: 45%;
    height: 30px;
    justify-content: center;
    border-radius: 20px;
    transition-duration: 0.3s;
    background: none;
  }

  button.recusar {
    border: 1px solid #f3534a;
    color: #f3534a;

    :hover {
      border-color: ${shade(0.3, '#f3534a')};
      color: ${shade(0.3, '#f3534a')};
    }
  }

  button.aceitar {
    border: 1px solid #67aa0e;
    color: #67aa0e;

    :hover {
      border-color: ${shade(0.3, '#67aa0e')};
      color: ${shade(0.3, '#67aa0e')};
    }
  }
`;

export const Form = styled(FormComponent)`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 6px #0000002e;
  background-color: #fff;

  textarea {
    width: calc(100% - 50px);
    padding: 10px;
    height: 100px;
    resize: none;
    border: none;

    ::placeholder {
      color: #8894a2;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    margin: 0 10px;
    transform: rotateZ(-40deg);
    transition-duration: 0.3s;
    background-color: #7b9db7;

    :hover {
      background-color: ${shade(0.1, '#7B9DB7')};
    }
  }
`;

export const FormModal = styled(FormComponent)`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
  @media screen and (max-width: 991px) {
    padding: 10px;
  }
`;

export const Title = styled.h2`
  color: #1d2433;
  font-size: 5rem;
  font-weight: 500;
  margin-bottom: -30px;

  @media screen and (max-width: 991px) {
    font-size: 3rem;
    text-align: center;
    padding: 0 30px;
  }
`;

export const InputGroup = styled.div`
  padding: 0 10px;
  width: 100%;
  display: flex;

  label {
    font-size: 1.5rem;
    color: #c5ccd6;
  }

  > div {
    padding: 10px;
    width: 100%;

    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid #d8d8d8;
      height: 40px;
      font-size: 1.5rem;
      color: #323643;
      padding: 0 10px;
    }

    input.active {
      border-bottom: 1px solid #323643;
    }

    textarea {
      display: block;
      width: 100%;
      border: 1px solid #d8d8d8;
      height: 100px;
      border-radius: 10px;
      margin-top: 15px;
      resize: none;
      padding: 10px;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 0 5px;
    display: block;

    > div {
      padding: 5px;

      input {
        height: 20px;
      }

      input.active {
        border-bottom: 1px solid #323643;
      }

      textarea {
        height: 50px;
      }
    }
  }
`;

export const ButtonModal = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 20px;

  button {
    border: none;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #173e4f;
    color: #fff;
    border-radius: 50px;
    height: 30px;
    margin-bottom: 15px;
    transition-duration: 0.3s;

    :hover {
      background-color: ${lighten(0.1, '#173E4F')};
    }
  }

  @media screen and (max-width: 991px) {
    button {
      width: 100%;
    }
  }
`;
