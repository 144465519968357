import React, { useState, useEffect } from 'react';
import InputMaskComponent from 'react-input-mask';
import PropTypes from 'prop-types';
import Input from '~/components/Input';

export default function InputMask({
  mask,
  name,
  value,
  placeholder,
  required,
  handleBlur,
  handleChangeHorarioInicio,
  handleChangeHorarioFim,
  onChange,
}) {
  const [newValue, setNewValue] = useState('');

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  function handleChangeInput(e) {
    onChange(e);
    if (handleChangeHorarioInicio) {
      handleChangeHorarioInicio(e.target.value);
    }

    if (handleChangeHorarioFim) {
      handleChangeHorarioFim(e.target.value);
    }

    setNewValue(e.target.value);
  }

  return (
    <InputMaskComponent
      mask={mask}
      value={newValue}
      onChange={handleChangeInput}
      onBlur={handleBlur}
    >
      {() => (
        <Input name={name} placeholder={placeholder} required={required} />
      )}
    </InputMaskComponent>
  );
}

InputMask.propTypes = {
  mask: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChangeHorarioInicio: PropTypes.func,
  handleChangeHorarioFim: PropTypes.func,
  onChange: PropTypes.func,
};

InputMask.defaultProps = {
  value: '',
  placeholder: '',
  required: false,
  handleBlur: () => {},
  handleChangeHorarioInicio: () => {},
  handleChangeHorarioFim: () => {},
  onChange: () => {},
};
