import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropsTypes from 'prop-types';

export default function AuthLayout({ children }) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
}

AuthLayout.propTypes = {
  children: PropsTypes.element.isRequired,
};
