import React, { useState, useEffect, useRef } from 'react';
import { useField } from '@rocketseat/unform';
import { IoMdAdd } from 'react-icons/io';
import PropTypes from 'prop-types';

import { Container } from './styles';
import InputComponent from '~/components/Input';

export default function InputFile({
  name,
  hangleImageChange,
  avatarPreview,
  className,
}) {
  const ref = useRef();
  const { defaultValue, registerField } = useField('avatar');

  const file = defaultValue && defaultValue.id;
  const [preview, setPreview] = useState(avatarPreview);

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: 'avatar_id',
        ref: ref.current,
        path: 'dataset.file',
      });
    }
  }, [ref, registerField]);

  useEffect(() => {
    setPreview(avatarPreview);
  }, [avatarPreview]);

  function handleChange(e) {
    const filePreview = e.target.files[0];
    if (filePreview) {
      setPreview(URL.createObjectURL(filePreview));
      hangleImageChange(filePreview);
    } else {
      setPreview('');
    }
  }

  return (
    <Container className={className}>
      <label htmlFor="avatar">
        {preview ? (
          <img src={preview} alt="Avatar" />
        ) : (
          <IoMdAdd size={30} color="#ccc" />
        )}

        <input
          type="file"
          id="avatar"
          accept="image/*"
          data-file={file}
          onChange={handleChange}
          ref={ref}
        />
      </label>
      <InputComponent type="hidden" name={name} value={preview} />
    </Container>
  );
}

InputFile.propTypes = {
  hangleImageChange: PropTypes.func.isRequired,
  name: PropTypes.func,
  avatarPreview: PropTypes.string,
  className: PropTypes.string,
};

InputFile.defaultProps = {
  name: 'file',
  avatarPreview: '',
  className: '',
};
