import styled from 'styled-components';
import { darken } from 'polished';

import bgLogin from '~/assets/defaults/bg-login.svg';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;

  > div {
    :nth-child(1) {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      > div:nth-child(1) {
        width: 80%;
        padding: 0 15px;
        margin: 0 auto;

        > div {
          display: flex;
          align-items: center;
          margin-bottom: 49px;

          div {
            :nth-child(1) {
              width: 25%;
              padding: 0 15px;

              img {
                width: 100%;
              }
            }

            h1 {
              color: #1d2433;
              font-size: 8rem;
              font-weight: 600;
            }

            h2 {
              font-size: 2rem;
              color: #9da3b4;
              font-weight: 500;
            }
          }
        }

        form {
          width: 100%;
          overflow-x: hidden;

          label.birthday {
            margin-right: 32px;
          }

          label.separator {
            display: block;
            width: 100%;
            text-align: center;
            color: #c5ccd6;
          }

          span.error {
            margin-top: -15px;
            display: block;
            font-size: 80%;
            color: #f3534a;
          }

          > div:nth-child(1) {
            height: 365px;
            overflow: hidden;
            position: relative;

            > div {
              width: 80%;
              position: absolute;
              transition: left 0.3s, opacity 0s;

              div.password {
                display: flex;
                border-bottom: 1px solid #d8d8d8;
                margin-bottom: 20px;
                position: relative;

                > input {
                  border: none;
                  margin-bottom: 0;
                }

                button {
                  border: none;
                  background: none;
                  svg {
                    transition: color 0.3s;
                  }

                  :hover {
                    svg {
                      color: ${darken('0.05', '#C6CBD4')} !important;
                    }
                  }
                }

                span.error {
                  position: absolute;
                  bottom: -20px;
                  left: 0;
                }
              }

              div.tipo {
                display: flex;
                border-bottom: 1px solid #d8d8d8;
                margin-bottom: 20px;
                align-items: center;

                > div {
                  position: relative;
                  padding-bottom: 10px;

                  input {
                    border: none;
                    margin: 0 10px;
                    width: 18px !important;
                  }

                  span.error {
                    position: absolute;
                    bottom: -5px;
                    left: 13px;
                  }
                }

                button {
                  border: none;
                  background: none;
                  svg {
                    transition: color 0.3s;
                  }

                  :hover {
                    svg {
                      color: ${darken('0.05', '#C6CBD4')} !important;
                    }
                  }
                }
              }
            }

            > div.old {
              left: -100%;
            }

            > div.active {
              left: 0;
              opacity: 1;
            }

            > div.next {
              left: 100%;
              opacity: 0;
            }

            > div:nth-child(5) {
              width: 100%;
            }
          }

          label {
            font-size: 1.8rem;
          }

          label.avatarLabel {
            display: block;
            text-align: center;
            color: #9da3b4;
          }

          div.avatarInput {
            flex-direction: column;
            text-align: center;

            label {
              margin: 0 auto 25px;
            }
          }

          div.select {
            margin-bottom: 20px;
            position: relative;
            z-index: 1000;
          }

          input.input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #d8d8d8;
            padding: 5px;
            margin-bottom: 20px;
            font-size: 1.8rem;

            ::placeholder {
              color: #c5ccd6;
            }
          }

          select {
            font-size: 1.8rem;
          }

          select {
            width: 100%;
            border: none;
            border-bottom: 1px solid #d8d8d8;
            padding: 5px;
            margin-bottom: 20px;
            ::placeholder {
              color: #c8cfd8;
            }
          }

          a {
            color: #ffca18;
            font-weight: 700;
            transition: color 0.3s;
            :hover {
              color: ${darken(0.1, '#ffca18')};
            }
          }

          > button {
            margin: 20px 0;
            width: 100%;
            border-radius: 50px;
            background: #173e4f;
            border: none;
            color: #fff;
            height: 35px;
            transition: background 0.3s;
            :hover {
              background: ${darken(0.05, '#173E4F')};
            }
          }

          div {
            p {
              color: #c8cfd8;
              margin-right: 10px;
            }
          }

          div.dataUser {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            > label {
              margin: 5px;
            }
          }

          > div:nth-child(3) {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    :nth-child(2) {
      width: 50%;
      background: url(${bgLogin});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  @media screen and (max-width: 991px) {
    display: block;
    padding: 20px 0;

    > div {
      :nth-child(1) {
        width: 100%;

        > div:nth-child(1) {
          width: 100%;

          > div {
            display: block;
            margin-bottom: 15px;

            div {
              text-align: center;
              :nth-child(1) {
                width: 100%;
                text-align: center;

                img {
                  width: 25%;
                }
              }

              h1 {
                font-size: 5rem;
              }
            }
          }

          form {
            width: 100%;
            overflow-x: hidden;

            label.birthday {
              margin-right: 32px;
            }

            label.separator {
              display: block;
              width: 100%;
              text-align: center;
              color: #c5ccd6;
            }

            > div:nth-child(1) {
              height: 300px;

              > div {
                width: 100%;
              }
            }
          }
        }
      }

      :nth-child(2) {
        display: none;
      }
    }
  }
`;

export const InputGroup = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.fr ? props.fr : '1fr')};
  justify-content: space-between;
  position: relative;

  label {
    display: block !important;
  }

  div.validadeGroup {
    width: ${props => (props.data ? '100%' : '95%')} !important;
  }

  .mr-auto {
    margin-right: auto;
  }

  .ml-auto {
    margin-left: auto;
  }

  span.error {
    position: absolute;
    bottom: 0;
  }

  select {
    width: 90%;
    border: none;
    border-bottom: 1px solid #d8d8d8;
    padding: 5px;
    margin-bottom: 20px;
    ::placeholder {
      color: #c8cfd8;
    }
  }
`;

export const TipoGroup = styled.div`
  transition-duration: 0.3s;
  overflow: hidden;
  height: ${props => (props.active ? '265px' : '0')};
`;

export const OtherCategory = styled.div`
  transition-duration: 0.3s;
  overflow: hidden;
  height: ${props => (props.active ? '36px' : '0')};
`;

export const ButtonGroup = styled.div`
  margin-top: -50px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: ${props =>
    props.passo === 1 ? 'flex-end' : 'space-between'};

  > button {
    margin: 20px 0;
    width: 49%;
    border-radius: 50px;
    border: none;
    color: #fff;
    height: 35px;
    transition: background 0.3s;

    :first-child {
      background: #aaa;
    }

    :last-child {
      background: #173e4f;
    }

    :hover {
      :first-child {
        background: ${darken(0.05, '#aaa')};
      }

      :last-child {
        background: ${darken(0.05, '#173E4F')};
      }
    }
  }

  .d-none {
    display: none;
  }

  @media screen and (max-width: 991px) {
    > button {
      margin: 20px 0 10px;
    }
  }
`;
