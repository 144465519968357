export function registerServiceRequest(
  title,
  descricao,
  endereco,
  horaInicio,
  horaFim,
  user_id,
  files
) {
  return {
    type: '@service/REGISTER_SERVICE_REQUEST',
    payload: {
      title,
      descricao,
      endereco,
      horaInicio,
      horaFim,
      user_id,
      files,
    },
  };
}

export function registerServiceSuccess(id) {
  return {
    type: '@service/REGISTER_SERVICE_SUCCESS',
    payload: { id },
  };
}

export function serviceRequest(id) {
  return {
    type: '@service/SERVICE_REQUEST',
    payload: { id },
  };
}

export function servicesRequestByCategoria(id) {
  return {
    type: '@service/SERVICES_REQUEST_BY_CATEGORIA',
    payload: { id },
  };
}

export function servicesSuccessByCategoria(id_categoria) {
  return {
    type: '@service/SERVICE_SUCCESS_BY_CATEGORIA',
    payload: { id_categoria },
  };
}

export function serviceSuccess(service) {
  return {
    type: '@service/SERVICE_SUCCESS',
    payload: { service },
  };
}

export function serviceFailure() {
  return {
    type: '@service/SERVICE_FAILURE',
  };
}
