/* eslint-disable react/no-danger */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { formatToTimeZone } from 'date-fns-timezone';

import api from '~/services/api';
import { formatPrice } from '~/util/format';

import {
  Container,
  Content,
  Servicos,
  Servico,
  Img,
  StatusGroup,
  Status,
  StatusButton,
  OtherStatusButton,
  Modal,
  Form,
  RadioGroup,
  ButtonGroup,
} from './styles';
import Input from '~/components/Input';
import InputRadio from '~/components/InputRadio';

export default function Historico() {
  const userId = useSelector(state => state.auth.user.id);
  const [proposals, setProposals] = useState([]);
  const [openProposal, setOpenProposal] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openConclude, setOpenConclude] = useState(false);
  const [openProvider, setOpenProvider] = useState(false);
  const [openClient, setOpenClient] = useState(false);
  const [openReason, setOpenReason] = useState(false);
  const [proposta, setProposta] = useState(`<div></div>`);
  const [proposalTitle, setProposalTitle] = useState(``);
  const [reasonData, setReasonData] = useState(``);
  const [proposalData, setProposalData] = useState(0);

  useEffect(() => {
    api.get(`proposal/user/${userId}`).then(response => {
      const data = response.data.map(proposal => {
        let buttonText;
        if (proposal.status === 0) {
          buttonText = 'Ver proposta';
        }
        if (proposal.status === 1) {
          buttonText = 'Cancelar';
        }
        if (
          proposal.status === 2 &&
          !proposal.clientReview &&
          userId === proposal.service.user.id
        ) {
          buttonText = 'Avaliar Cliente';
        }
        if (
          proposal.status === 2 &&
          !proposal.providerReview &&
          userId !== proposal.service.user.id
        ) {
          buttonText = 'Avaliar Prestador';
        }
        if (proposal.status === 3) {
          buttonText = 'Motivo';
        }
        if (proposal.status === 4) {
          buttonText = 'Motivo';
        }

        return {
          id: proposal.id,
          title: proposal.title,
          image: proposal.service.files[0].url,
          cliente_id: proposal.id_user_two,
          provider_id: proposal.service.user.id,
          name: proposal.service.user.name,
          status: proposal.status,
          status_name: proposal.status_name,
          reason: proposal.reason,
          date: formatToTimeZone(proposal.updated_at, 'DD/MM/YYYY', {
            timeZone: 'Europe/Berlin',
          }),
          value: formatPrice(proposal.value),
          delivery_date: proposal.delivery_date
            ? formatToTimeZone(proposal.delivery_date, 'DD/MM/YYYY', {
                timeZone: 'Europe/Berlin',
              })
            : null,
          note: proposal.note,
          providerReview: proposal.providerReview,
          clientReview: proposal.clientReview,
          buttonText,
        };
      });
      setProposals(data);
    });
  }, [userId]);

  const handleClick = useCallback((action, data) => {
    setProposalData(data);
    switch (action) {
      case 'Ver proposta':
        setProposta(`
          <div style="padding: 10px;">
            <h3 style="text-align: center; margin-bottom: 0">${data.title}</h3>
            <h2 style="text-align: center; margin-bottom: 0"><small>Valor:</small> ${
              data.value
            }</h2>
            <p style="text-align: center; margin-bottom: 0"><b>Data de entrega:</b> ${
              data.delivery_date ? data.delivery_date : 'Não possui'
            }</p>
            <p style="text-align: center; margin-bottom: 0"><b>Obs:</b> ${
              data.note ? data.note : 'Não possui'
            }</p>
          </div>
        `);
        setOpenProposal(true);
        break;
      case 'Cancelar':
        setOpenCancel(true);
        break;
      case 'Concluir':
        setProposalTitle(data.title);
        setOpenConclude(true);
        break;
      case 'Avaliar Prestador':
        setOpenProvider(true);
        break;
      case 'Avaliar Cliente':
        setOpenClient(true);
        break;
      case 'Motivo':
        setReasonData(data.reason);
        setOpenReason(true);
        break;
      default:
        setOpenProposal(true);
    }
  }, []);

  const deadlineData = useMemo(() => {
    return [
      { value: 'Antes do prazo.', label: 'Antes do prazo.' },
      { value: 'No prazo.', label: 'No prazo.' },
      { value: 'Depois o prazo.', label: 'Depois o prazo.' },
    ];
  }, []);

  const paymentData = useMemo(() => {
    return [
      { value: 'Antes do prazo.', label: 'Sim, antes do prazo.' },
      { value: 'No fim do prazo.', label: 'Sim, No fim do prazo.' },
      { value: 'Depois o prazo.', label: 'Ainda não me pagou.' },
      { value: 'Não pago.', label: 'Não fui pago.' },
    ];
  }, []);

  const communicationData = useMemo(() => {
    return [
      { value: 'É educado(a).', label: 'Foi educado.' },
      {
        value: 'Fala somente o essencial.',
        label: 'Fala somente o essencial.',
      },
      { value: 'É grosseiro(a).', label: 'Foi grosseiro.' },
    ];
  }, []);

  const noteData = useMemo(() => {
    return [
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
      { value: 4, label: 4 },
      { value: 5, label: 5 },
    ];
  }, []);

  const handleRefuseProposal = useCallback(async () => {
    const response = await api.put(`proposal/${proposalData.id}`, {
      aproved_at: new Date(),
      status: 4,
      status_name: 'Recusado',
    });

    if (response.data) {
      const selectedProposal = proposals.find(
        proposal => proposal.id === proposalData.id
      );
      const proposalIndex = proposals.findIndex(
        proposal => proposal.id === proposalData.id
      );
      selectedProposal.status = 4;
      selectedProposal.status_name = 'Recusado';
      proposals[proposalIndex] = selectedProposal;
      setOpenProposal(false);
    }
  }, [proposalData.id, proposals]);

  const handleAcceptProposal = useCallback(async () => {
    const response = await api.put(`proposal/${proposalData.id}`, {
      aproved_at: new Date(),
      status: 1,
      status_name: 'Em Andamento',
    });

    if (response.data) {
      const selectedProposal = proposals.find(
        proposal => proposal.id === proposalData.id
      );
      const proposalIndex = proposals.findIndex(
        proposal => proposal.id === proposalData.id
      );
      selectedProposal.status = 1;
      selectedProposal.status_name = 'Em Andamento';
      selectedProposal.buttonText = 'Cancelar';
      proposals[proposalIndex] = selectedProposal;
      setOpenProposal(false);
    }
  }, [proposalData.id, proposals]);

  const handleSubmitReason = useCallback(
    async ({ reason }) => {
      const response = await api.put(`proposal/${proposalData.id}`, {
        aproved_at: new Date(),
        reason,
        status: 3,
        status_name: 'Cancelado',
      });

      if (response.data) {
        const selectedProposal = proposals.find(
          proposal => proposal.id === proposalData.id
        );
        const proposalIndex = proposals.findIndex(
          proposal => proposal.id === proposalData.id
        );
        selectedProposal.status = 3;
        selectedProposal.status_name = 'Cancelado';
        selectedProposal.buttonText = 'Motivo';
        selectedProposal.reason = reason;
        proposals[proposalIndex] = selectedProposal;
        setOpenCancel(false);
      }
    },
    [proposalData.id, proposals]
  );

  const handleConclude = useCallback(async () => {
    const response = await api.put(`proposal/${proposalData.id}`, {
      aproved_at: new Date(),
      status: 2,
      status_name: 'Concluído',
    });

    if (response.data) {
      const selectedProposal = proposals.find(
        proposal => proposal.id === proposalData.id
      );
      const proposalIndex = proposals.findIndex(
        proposal => proposal.id === proposalData.id
      );
      selectedProposal.status = 2;
      selectedProposal.status_name = 'Concluído';
      selectedProposal.buttonText = 'Avaliar Cliente';
      proposals[proposalIndex] = selectedProposal;
      setOpenConclude(false);
    }
  }, [proposalData.id, proposals]);

  const handleSubmitProviderReview = useCallback(
    async ({ deadline, communication, experience, note }) => {
      const response = await api.post('provider-reviews', {
        id_user: proposalData.provider_id,
        id_appraiser: proposalData.cliente_id,
        id_proposal: proposalData.id,
        on_time: deadline,
        communication,
        experience,
        note,
      });

      if (response.data) {
        const selectedProposal = proposals.find(
          proposal => proposal.id === proposalData.id
        );
        const proposalIndex = proposals.findIndex(
          proposal => proposal.id === proposalData.id
        );
        selectedProposal.providerReview = { id: response.data.id };
        proposals[proposalIndex] = selectedProposal;

        setOpenProvider(false);
      }
    },
    [
      proposalData.cliente_id,
      proposalData.id,
      proposalData.provider_id,
      proposals,
    ]
  );

  const handleSubmitClientReview = useCallback(
    async ({ payment, communication, experience, note }) => {
      const response = await api.post('client-reviews', {
        id_user: proposalData.provider_id,
        id_appraiser: proposalData.cliente_id,
        id_proposal: proposalData.id,
        payment,
        communication,
        experience,
        note,
      });

      if (response.data) {
        const selectedProposal = proposals.find(
          proposal => proposal.id === proposalData.id
        );
        const proposalIndex = proposals.findIndex(
          proposal => proposal.id === proposalData.id
        );
        selectedProposal.clientReview = { id: response.data.id };
        proposals[proposalIndex] = selectedProposal;

        setOpenClient(false);
      }
    },
    [
      proposalData.cliente_id,
      proposalData.id,
      proposalData.provider_id,
      proposals,
    ]
  );

  return (
    <>
      <Container>
        <h1>Historico</h1>
        <p>Serviços realizados</p>
        <Content>
          <Servicos>
            {proposals.map(proposal => (
              <Servico key={proposal.id}>
                <div>
                  <Img src={proposal.image} />
                  <div>
                    <h3>{proposal.title}</h3>
                    <p>{proposal.name}</p>
                    <StatusGroup
                      provider={
                        userId === proposal.provider_id && proposal.status === 1
                      }
                    >
                      <Status status={proposal.status}>
                        {proposal.status_name}
                      </Status>

                      <>
                        <StatusButton
                          status={proposal.status}
                          provider={userId === proposal.provider_id}
                          providerReview={proposal.providerReview}
                          clientReview={proposal.clientReview}
                          onClick={() =>
                            handleClick(proposal.buttonText, proposal)
                          }
                          className="modalButton"
                        >
                          {proposal.buttonText}
                        </StatusButton>
                        <OtherStatusButton
                          provider={
                            userId === proposal.provider_id &&
                            proposal.status === 1
                          }
                          status={proposal.status}
                          onClick={() => handleClick('Concluir', proposal)}
                          className="modalButton"
                        >
                          Concluir
                        </OtherStatusButton>
                      </>
                    </StatusGroup>
                  </div>
                </div>
              </Servico>
            ))}
          </Servicos>
        </Content>
      </Container>
      <Modal opened={openProposal} close={() => setOpenProposal(false)}>
        <h2>Proposta</h2>
        <hr />
        <div dangerouslySetInnerHTML={{ __html: proposta }} />
        <ButtonGroup>
          <button
            type="button"
            className="fechar"
            onClick={handleRefuseProposal}
          >
            Recusar
          </button>
          <button
            type="button"
            className="concluir"
            onClick={handleAcceptProposal}
          >
            Aceitar
          </button>
        </ButtonGroup>
      </Modal>
      <Modal opened={openCancel} close={() => setOpenCancel(false)}>
        <h2>Cancelar Serviço</h2>
        <hr />
        <Form onSubmit={handleSubmitReason}>
          <label>Informe o motivo do cancelamento:</label>
          <Input name="reason" multiline />
          <button type="submit" className="cancelar">
            Cancelar
          </button>
        </Form>
      </Modal>
      <Modal opened={openConclude} close={() => setOpenConclude(false)}>
        <h2>Concluir Serviço</h2>
        <hr />
        <p>{proposalTitle}</p>
        <ButtonGroup>
          <button
            type="button"
            className="fechar"
            onClick={() => setOpenConclude(false)}
          >
            Fechar
          </button>
          <button type="button" className="concluir" onClick={handleConclude}>
            Concluir
          </button>
        </ButtonGroup>
      </Modal>
      <Modal opened={openProvider} close={() => setOpenProvider(false)}>
        <h2>Avaliar Prestador</h2>
        <hr />
        <Form onSubmit={handleSubmitProviderReview}>
          <label>O serviço foi entregue no prazo?</label>
          <InputRadio name="deadline" options={deadlineData} />
          <label>A comunicação foi boa?</label>
          <InputRadio name="communication" options={communicationData} />
          <Input
            name="experience"
            placeholder="Conte sua experiência"
            multiline
          />
          <label>Qual nota você da para o prestador?</label>
          <RadioGroup>
            <InputRadio name="note" options={noteData} />
          </RadioGroup>
          <ButtonGroup>
            <button
              type="button"
              className="cancelar"
              onClick={() => setOpenProvider(false)}
            >
              Cancelar
            </button>
            <button type="submit" className="avaliar">
              Avaliar
            </button>
          </ButtonGroup>
        </Form>
      </Modal>
      <Modal opened={openClient} close={() => setOpenClient(false)}>
        <h2>Avaliar Cliente</h2>
        <hr />
        <Form onSubmit={handleSubmitClientReview}>
          <label>O cliente realizou o pagamento?</label>
          <InputRadio name="payment" options={paymentData} />
          <label>A comunicação foi boa?</label>
          <InputRadio name="communication" options={communicationData} />
          <Input
            name="experience"
            placeholder="Conte sua experiência"
            multiline
          />
          <label>Qual nota você da para o cliente?</label>
          <RadioGroup>
            <InputRadio name="note" options={noteData} />
          </RadioGroup>
          <ButtonGroup>
            <button
              type="button"
              className="cancelar"
              onClick={() => setOpenClient(false)}
            >
              Cancelar
            </button>
            <button type="submit" className="avaliar">
              Avaliar
            </button>
          </ButtonGroup>
        </Form>
      </Modal>
      <Modal opened={openReason} close={() => setOpenReason(false)}>
        <h2>Motivo</h2>
        <hr />
        <p>{reasonData}</p>
      </Modal>
    </>
  );
}
