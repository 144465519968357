import styled, { css } from 'styled-components';

export const BgHeader = styled.header`
  background: #fff;
  width: 150px;

  @media screen and (max-width: 991px) {
    width: 0;
  }
`;

export const Content = styled.div`
  background: #fff;
  z-index: 1;
  position: fixed;
  left: 0;
  height: 100vh;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;

    > img {
      height: 60px;
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 50vh;
      margin: 100% 0;

      li {
        width: 100%;

        a {
          display: flex;
          align-items: center;
          color: #b7ccd4;
          font-size: 1.4rem;
          font-weight: bold;
          transition-duration: 0.3s;

          svg {
            margin-right: 15px;
            transition-duration: 0.3s;
          }

          :hover {
            color: #173e4f;

            svg {
              color: #173e4f !important;
            }
          }
        }
      }

      li.active {
        a {
          color: #173e4f;

          svg {
            color: #173e4f !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    bottom: 0;
    height: auto;
    width: 100%;

    div {
      padding: 10px 0;

      > img {
        display: none;
      }

      ul {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: auto;
        margin: 0;

        li {
          width: auto;

          a {
            justify-content: center;
            flex-direction: column;
            svg {
              margin: 5px 0;
            }
          }
        }
      }
    }
  }
`;

export const MenuItem = styled.li`
  margin: 0 30px;
  font-weight: bold;
  font-size: 15px;

  svg {
    display: none;
  }

  a {
    transition: opacity 0.3s;
    color: ${props => (props.active ? '#707070' : '#9e9c9c')};
    border-bottom: 3px solid
      ${props => (props.active ? '#41637e' : 'transparent')};

    :hover {
      color: #707070;
      ${props =>
        !props.perfil
          ? css`
              border-bottom: 3px solid
                ${!props.perfil ? '#41637e' : 'transparent'};
            `
          : css`
              opacity: 0.7;
            `};
    }
  }

  button {
    background: transparent;
    border: none;
    font-weight: bold;
    transition: opacity 0.3s;
    color: #9e9c9c;
    border-bottom: 3px solid 'transparent';
    :hover {
      color: #ff0000;
    }
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #ddd;
  }
`;
