import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import { IoMdEye } from 'react-icons/io';
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import Input from '~/components/Input';

import api from '~/services/api';
import getValidationErros from '~/util/getValidationsErrors';

import { signUpRequest } from '~/store/modules/auth/actions';

import {
  Content,
  InputGroup,
  TipoGroup,
  OtherCategory,
  ButtonGroup,
} from './styles';

import MaskInput from '~/components/MaskInput';
import InputRadio from '~/components/InputRadio';
import InputFile from '~/components/InputFile';
import Select from '~/components/Select';

import Logo from '~/assets/logos/logo.svg';

export default function Cadastro() {
  const formRef = useRef();
  const dispatch = useDispatch();

  const [typePassword, setTypePassword] = useState('password');
  const [passo, setPasso] = useState(1);
  const [passoPrev, setPassoPrev] = useState(1);
  const [passoNext, setPassoNext] = useState(2);
  const [passoFinal, setPassoFinal] = useState(false);
  const [classe1, setClasse1] = useState('active');
  const [classe2, setClasse2] = useState('next');
  const [classe3, setClasse3] = useState('next');
  const [classe4, setClasse4] = useState('next');
  const [classe5, setClasse5] = useState('next');
  const [personType, setPersonType] = useState('');
  const [sex, setSex] = useState('');
  const [street, setStreet] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState({});
  const [complement, setComplement] = useState('');
  const [categories, setCategories] = useState([
    { value: 0, label: 'Apenas cliente' },
  ]);
  const [otherRamo, setOtherRamo] = useState(false);
  const [name, setName] = useState('');
  const [documento, setDocumento] = useState('');
  const [fileData, setFileData] = useState([]);

  const tipo = useMemo(
    () => [
      { value: 'PF', label: 'Pessoa física' },
      { value: 'PJ', label: 'Pessoa jurídica' },
    ],
    []
  );

  const sexo = useMemo(
    () => [
      { value: 'M', label: 'Masculino' },
      { value: 'F', label: 'Feminino' },
    ],
    []
  );

  const estados = useMemo(
    () => [
      { value: 'AC', label: 'Acre' },
      { value: 'AL', label: 'Alagoas' },
      { value: 'AP', label: 'Amapá' },
      { value: 'AM', label: 'Amazonas' },
      { value: 'BA', label: 'Bahia' },
      { value: 'CE', label: 'Ceará' },
      { value: 'DF', label: 'Distrito Federal' },
      { value: 'ES', label: 'Espírito Santo' },
      { value: 'GO', label: 'Goiás' },
      { value: 'MA', label: 'Maranhão' },
      { value: 'MT', label: 'Mato Grosso' },
      { value: 'MS', label: 'Mato Grosso do Sul' },
      { value: 'MG', label: 'Minas Gerais' },
      { value: 'PA', label: 'Pará' },
      { value: 'PB', label: 'Paraíba' },
      { value: 'PR', label: 'Paraná' },
      { value: 'PE', label: 'Pernambuco' },
      { value: 'PI', label: 'Piauí' },
      { value: 'RJ', label: 'Rio de Janeiro' },
      { value: 'RN', label: 'Rio Grande do Norte' },
      { value: 'RS', label: 'Rio Grande do Sul' },
      { value: 'RO', label: 'Rondônia' },
      { value: 'RR', label: 'Roraima' },
      { value: 'SC', label: 'Santa Catarina' },
      { value: 'SP', label: 'São Paulo' },
      { value: 'SE', label: 'Sergipe' },
      { value: 'TO', label: 'Tocantins' },
    ],
    []
  );

  useEffect(() => {
    api
      .get('categories')
      .then(response => {
        const data = response.data.map(category => {
          return {
            value: category.id,
            label: category.name,
          };
        });
        setCategories(oldState => [...oldState, ...data]);
      })
      .finally(() => {
        setCategories(oldState => [
          ...oldState,
          { value: 'outro', label: 'Outro ramo' },
        ]);
      });
  }, []);

  const handleClick = useCallback(() => {
    setTypePassword(oldState =>
      oldState === 'password' ? 'text' : 'password'
    );
  }, []);

  const handleClickPasso = useCallback(passoSelected => {
    setPassoFinal(passoSelected === 5);
    switch (passoSelected) {
      case 1:
        setPassoNext(2);
        setClasse1('active');
        setClasse2('next');
        break;
      case 2:
        setPassoPrev(1);
        setPassoNext(3);
        setClasse1('old');
        setClasse2('active');
        setClasse3('next');
        break;
      case 3:
        setPassoPrev(2);
        setPassoNext(4);
        setClasse2('old');
        setClasse3('active');
        setClasse4('next');
        break;
      case 4:
        setPassoPrev(3);
        setPassoNext(5);
        setClasse3('old');
        setClasse4('active');
        setClasse5('next');
        break;
      case 5:
        setPassoPrev(4);
        setClasse4('old');
        setClasse5('active');
        break;
      default:
        setPasso(1);
        setPassoNext(2);
        setPassoPrev(1);
        setClasse1('active');
        setClasse2('next');
    }
    setPasso(passoSelected);
  }, []);

  const handleChangeCep = useCallback(
    async cep => {
      if (cep.length === 9) {
        const response = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );
        if (!response.data.erro) {
          setStreet(response.data.logradouro);
          setNeighborhood(response.data.bairro);
          setCity(response.data.localidade);
          const stateSelected = estados.find(
            estado => estado.value === response.data.uf
          );
          setState(stateSelected);
          setComplement(response.data.complemento);
        }
      }
    },
    [estados]
  );

  const handleRamoSelected = useCallback(ramoSelected => {
    if (ramoSelected === 'outro') {
      setOtherRamo(true);
    } else {
      setOtherRamo(false);
    }
  }, []);

  const hangleImageChange = useCallback(async dataFile => {
    const formData = new FormData();
    formData.append('file', dataFile);
    setFileData(formData);
  }, []);

  const handleClickSave = useCallback(() => {
    const submitButton = document.getElementById('submitButton');
    submitButton.click();
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        if (formRef.current) {
          formRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório.'),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('O e-mail é obrigatório.'),
          password: Yup.string().required('A senha é obrigatória.'),
          person_type: Yup.string().required('Selecione um dos valores.'),
          document: Yup.string().required(
            'O número do documento é obrigatório.'
          ),
          birthday: Yup.string().when('person_type', {
            is: 'PF',
            then: Yup.string().required('a data de nascimento é obrigatória.'),
          }),
          sex: Yup.string().when('person_type', {
            is: 'PF',
            then: Yup.string().required('Selecione um dos valores.'),
          }),
          rg: Yup.string().when('person_type', {
            is: 'PF',
            then: Yup.string().required('O RG é obrigatório.'),
          }),
          company_name: Yup.string().when('person_type', {
            is: 'PJ',
            then: Yup.string().required('A razão social é obrigatória.'),
          }),
          cep: Yup.string().required('O CEP é obrigatório.'),
          street: Yup.string().required('A rua é obrigatória.'),
          number: Yup.string().required('O número é obrigatório.'),
          neighborhood: Yup.string().required('O bairro é obrigatório.'),
          city: Yup.string().required('A cidade é obrigatória.'),
          state: Yup.string().required('O estado é obrigatório.'),
          complement: Yup.string(),
          celphone: Yup.string(),
          telphone: Yup.string().when('celphone', {
            is: celphone => celphone.length === 0,
            then: Yup.string().required(
              'O telefone é obrigatório, se o celular não for informado.'
            ),
          }),
          new_category: Yup.string(),
          category: Yup.string().when('new_category', {
            is: new_category => new_category.length === 0,
            then: Yup.string().required('O ramo do negocio é obrigatório.'),
          }),
          file: Yup.string().required('O avatar é obrigatório.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          email,
          password,
          birthday,
          rg,
          company_name,
          cep,
          number,
          celphone,
          telphone,
          category,
          new_category,
        } = data;

        let id_category = category;

        if (category === 'outro') {
          const response = await api.post('categories', {
            name: new_category,
          });
          if (response.data) {
            id_category = response.data.id;
          }
        }

        dispatch(
          signUpRequest(
            fileData,
            name,
            email,
            password,
            personType,
            birthday,
            sex,
            rg,
            documento,
            company_name,
            celphone,
            telphone,
            cep,
            street,
            number,
            neighborhood,
            city,
            state,
            complement,
            id_category
          )
        );
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          console.log(errors);
          setPassoFinal(false);
          setClasse5('next');
          if (
            errors.name !== undefined ||
            errors.email !== undefined ||
            errors.password !== undefined
          ) {
            setPasso(1);
            setPassoNext(2);
            setPassoPrev(1);
            setClasse1('active');
            setClasse2('next');
          } else if (
            errors.person_type !== undefined ||
            errors.document !== undefined ||
            errors.birthday !== undefined ||
            errors.sex !== undefined ||
            errors.rg !== undefined ||
            errors.company_name !== undefined
          ) {
            setPassoPrev(1);
            setPassoNext(3);
            setClasse1('old');
            setClasse2('active');
            setClasse3('next');
          } else if (
            errors.cep !== undefined ||
            errors.street !== undefined ||
            errors.number !== undefined ||
            errors.neighborhood !== undefined ||
            errors.city !== undefined ||
            errors.state !== undefined
          ) {
            setPassoPrev(2);
            setPassoNext(4);
            setClasse2('old');
            setClasse3('active');
            setClasse4('next');
          } else if (
            errors.telphone !== undefined ||
            errors.category !== undefined
          ) {
            setPassoPrev(3);
            setPassoNext(5);
            setClasse3('old');
            setClasse4('active');
            setClasse5('next');
          } else if (errors.file !== undefined) {
            setPassoFinal(true);
            setPassoPrev(4);
            setClasse4('old');
            setClasse5('active');
          }
          if (formRef.current) {
            formRef.current.setErrors(errors);
          }
        }
      }
    },
    [
      city,
      complement,
      dispatch,
      documento,
      fileData,
      name,
      neighborhood,
      personType,
      sex,
      state,
      street,
    ]
  );

  return (
    <Content>
      <div>
        <div>
          <div>
            <div>
              <img src={Logo} alt="" />
            </div>
            <div>
              <h1>Cadastro</h1>
              <h2>Faça seu cadastro e tenha acesso a todo conteúdo Wodo</h2>
            </div>
          </div>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div>
              <div className={classe1}>
                <Input
                  className="input"
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Nome"
                  onChange={e => setName(e.target.value)}
                />
                <Input
                  className="input"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="E-mail"
                />

                <div className="password">
                  <Input
                    className="input"
                    id="password"
                    type={typePassword}
                    name="password"
                    placeholder="Senha"
                  />
                  <button type="button" onClick={handleClick}>
                    <IoMdEye size={25} color="#C6CBD4" />
                  </button>
                </div>
              </div>
              <div className={classe2}>
                <div className="tipo">
                  <InputRadio
                    name="person_type"
                    options={tipo}
                    handleChange={setPersonType}
                  />
                </div>

                <TipoGroup active={personType === 'PF'}>
                  <MaskInput
                    className="input"
                    kind="cpf"
                    name={personType === 'PF' ? 'document' : 'document-pj'}
                    placeholder="CPF"
                    onChange={value => setDocumento(value)}
                  />
                  <MaskInput
                    className="input"
                    kind="datetime"
                    options={{
                      format: 'DD/MM/YYYY',
                    }}
                    name="birthday"
                    placeholder="Data de nascimento"
                    mask="99/99/9999"
                  />
                  <label htmlFor="sex">Sexo</label>
                  <div className="tipo">
                    <InputRadio
                      name="sex"
                      options={sexo}
                      handleChange={value => setSex(value)}
                    />
                  </div>
                  <MaskInput
                    className="input"
                    kind="custom"
                    options={{
                      mask: '99.999.999-S',
                    }}
                    name="rg"
                    placeholder="RG"
                  />
                </TipoGroup>

                <TipoGroup active={personType === 'PJ'}>
                  <MaskInput
                    className="input"
                    kind="cnpj"
                    name={personType === 'PJ' ? 'document' : 'document-pf'}
                    placeholder="CNPJ"
                    onChange={value => setDocumento(value)}
                  />
                  <Input
                    className="input"
                    id="company_name"
                    name="company_name"
                    placeholder="Razão Social"
                  />
                </TipoGroup>
              </div>
              <div className={classe3}>
                <MaskInput
                  className="input"
                  kind="zip-code"
                  name="cep"
                  placeholder="CEP"
                  mask="99999-999"
                  onChange={handleChangeCep}
                />

                <Input
                  className="input"
                  id="street"
                  type="text"
                  name="street"
                  placeholder="Rua"
                  value={street}
                  onChange={e => setStreet(e.target.value)}
                />

                <InputGroup fr="1fr 1fr">
                  <div className="validadeGroup mr-auto">
                    <Input
                      className="input"
                      id="number"
                      type="text"
                      name="number"
                      placeholder="Número"
                    />
                  </div>
                  <div className="validadeGroup ml-auto">
                    <Input
                      className="input"
                      id="neighborhood"
                      type="text"
                      name="neighborhood"
                      placeholder="Bairro"
                      value={neighborhood}
                      onChange={e => setNeighborhood(e.target.value)}
                    />
                  </div>
                </InputGroup>

                <InputGroup fr="1fr 1fr">
                  <div className="validadeGroup mr-auto">
                    <Input
                      className="input"
                      id="city"
                      type="text"
                      name="city"
                      placeholder="Cidade"
                      value={city}
                      onChange={e => setCity(e.target.value)}
                    />
                  </div>
                  <div className="validadeGroup ml-auto">
                    <Select
                      className="select"
                      id="state"
                      name="state"
                      placeholder="Estado"
                      options={estados}
                      selected={state}
                    />
                  </div>
                </InputGroup>

                <Input
                  className="input"
                  id="complement"
                  type="text"
                  name="complement"
                  placeholder="Complemento (Opcional)"
                  value={complement}
                  onChange={e => setComplement(e.target.value)}
                />
              </div>
              <div className={classe4}>
                <MaskInput
                  className="input"
                  kind="cel-phone"
                  name="celphone"
                  placeholder="Celular"
                />
                <label className="separator">e/ou</label>
                <MaskInput
                  className="input"
                  kind="cel-phone"
                  name="telphone"
                  placeholder="Telefone"
                />
                <Select
                  className="select"
                  id="category"
                  name="category"
                  placeholder="Ramo de negocio"
                  options={categories}
                  handleSelect={handleRamoSelected}
                />
                <OtherCategory active={otherRamo}>
                  <Input
                    className="input"
                    id="new_category"
                    name="new_category"
                    placeholder="Nome do Ramo"
                  />
                </OtherCategory>
              </div>
              <div className={classe5}>
                <label htmlFor="avatar" className="avatarLabel">
                  Adicione uma foto
                </label>
                <InputFile
                  className="avatarInput"
                  hangleImageChange={hangleImageChange}
                />
                <div className="dataUser">
                  <label>{name}</label>
                  <label>{documento}</label>
                </div>
              </div>
            </div>
            <ButtonGroup passo={passo}>
              {passo !== 1 && (
                <button
                  type="button"
                  onClick={() => handleClickPasso(passoPrev)}
                >
                  Voltar
                </button>
              )}
              {!passoFinal ? (
                <button
                  type="button"
                  onClick={() => handleClickPasso(passoNext)}
                >
                  Próximo
                </button>
              ) : (
                <>
                  <button id="submitButton" type="submit" className="d-none" />
                  <button
                    type="button"
                    className="finalizar"
                    onClick={handleClickSave}
                  >
                    Finalizar
                  </button>
                </>
              )}
            </ButtonGroup>
            <div>
              <p>Já possui uma conta?</p>
              <Link to="/">Logar</Link>
            </div>
          </Form>
        </div>
      </div>
      <div />
    </Content>
  );
}
