import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import { IoMdEye } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { signInRequest } from '~/store/modules/auth/actions';

import { Content } from './styles';

import Logo from '~/assets/logos/logo.svg';

export default function Login() {
  const [typePassword, setTypePassword] = useState('password');
  const dispatch = useDispatch();

  const schema = useMemo(() => {
    return Yup.object().shape({
      email: Yup.string()
        .email('Coloque um e-mail válido')
        .required('O E-mail é obrigatório'),
      password: Yup.string().required('A senha é obrigatória'),
    });
  }, []);

  const handleClick = useCallback(() => {
    setTypePassword(typePassword === 'password' ? 'text' : 'password');
  }, [typePassword]);

  const handleSubmit = useCallback(
    ({ email, password }) => {
      dispatch(signInRequest(email, password));
    },
    [dispatch]
  );

  return (
    <Content>
      <div>
        <div>
          <div>
            <div>
              <img src={Logo} alt="" />
            </div>
            <div>
              <h1>Logar</h1>
              <h2>Bem-vindo de volta!</h2>
            </div>
          </div>
          <Form schema={schema} onSubmit={handleSubmit}>
            <label htmlFor="email">E-mail</label>
            <Input
              type="email"
              name="email"
              placeholder="example@example.com"
            />
            <label htmlFor="password">Senha</label>
            <label htmlFor="password">
              <Input type={typePassword} name="password" placeholder="******" />
              <button type="button" onClick={handleClick}>
                <IoMdEye size={25} color="#C6CBD4" />
              </button>
            </label>
            <Link to="/esqueci-minha-senha">Esqueceu a senha?</Link>
            <button type="submit">Entrar</button>
            <div>
              <p>Não tem uma conta?</p>
              <Link to="/cadastrar">Cadastre-se</Link>
            </div>
          </Form>
        </div>
      </div>
      <div />
    </Content>
  );
}
