/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import { IoMdEye } from 'react-icons/io';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import { Content } from './styles';

import Logo from '~/assets/logos/logo.svg';

const schema = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, 'A nova senha tem que ter no mínino 6 caracteres')
    .required('A nova senha é obrigatória'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Senhas não combinam'
  ),
});

export default function TrocarSenha() {
  const params = useParams();
  const [typePassword, setTypePassword] = useState('password');

  const handleClick = useCallback(() => {
    setTypePassword(typePassword === 'password' ? 'text' : 'password');
  }, [typePassword]);

  const handleSubmit = useCallback(
    async ({ newPassword, passwordConfirmation }) => {
      if (newPassword === passwordConfirmation) {
        try {
          const response = await api.post('changePassword', {
            password: newPassword,
            token: params.token,
          });

          if (response.data) {
            Swal.fire(
              'Senha alterada!',
              'Alteração de senha realizada com sucesso.',
              'success'
            ).then(() => {
              history.push('/');
            });
          } else {
            Swal.fire(
              'Opsss...',
              'Ocorreu um erro ao fazer a troca, tente novamente.',
              'error'
            );
          }
        } catch (e) {
          Swal.fire(
            'Opsss...',
            'Ocorreu um erro ao fazer a troca, <br>tente novamente.',
            'error'
          );
        }
      } else {
        Swal.fire('Opsss...', 'As senhas não combinam', 'error');
      }
    },
    [params.token]
  );

  return (
    <Content>
      <div>
        <div>
          <div>
            <div>
              <img src={Logo} alt="" />
            </div>
            <div>
              <h1>Trocar Senha</h1>
              <h2>
                Para trocar a senha basta <br />
                inseri-la abaixo e depois repeti-la.
              </h2>
            </div>
          </div>
          <Form schema={schema} onSubmit={handleSubmit}>
            <div>
              <label htmlFor="newPassword">Nova Senha</label>
              <label className="inputGroup" htmlFor="newPassword">
                <Input
                  type={typePassword}
                  name="newPassword"
                  placeholder="******"
                />
                <button type="button" onClick={handleClick}>
                  <IoMdEye size={25} color="#C6CBD4" />
                </button>
              </label>
            </div>
            <div>
              <label htmlFor="passwordConfirmation">Confirmar Senha</label>
              <label className="inputGroup" htmlFor="passwordConfirmation">
                <Input
                  type={typePassword}
                  name="passwordConfirmation"
                  placeholder="******"
                />
                <button type="button" onClick={handleClick}>
                  <IoMdEye size={25} color="#C6CBD4" />
                </button>
              </label>
            </div>
            <button type="submit">Enviar</button>
            <div>
              <Link to="/">Login</Link>
            </div>
          </Form>
        </div>
      </div>
      <div />
    </Content>
  );
}
