import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaSearch, FaTimes } from 'react-icons/fa';

import { Content, Search, Filter, Options, Option } from './styles';

import ServicosList from './ServicosList';
import Categorias from './Categorias';
import Destaques from './Destaques';

export default function Servicos() {
  const history = useHistory();
  const location = useLocation();
  const [page, setPage] = useState('');
  const inputRef = useRef(null);
  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');

  useEffect(() => {
    let categoria = location.pathname.substring(21);
    categoria = categoria.charAt(0).toUpperCase() + categoria.slice(1);
    categoria = categoria.replace(/-/g, ' ');

    setCategory(categoria);

    setPage(location.pathname.substring(10));
  }, [location.pathname]);

  useEffect(() => {
    if (search && category) {
      setFilters([search, category]);
    } else if (search) {
      setFilters([search]);
    } else if (category) {
      setFilters([category]);
    } else {
      setFilters([]);
    }
  }, [search, category]);

  const removeFilter = useCallback(
    index => {
      if (filters[index] === category) {
        setCategory('');
        history.push('/servicos');
      } else {
        if (inputRef.current) {
          inputRef.current.value = '';
        }
        setSearch('');
      }
    },
    [category, filters, history]
  );

  const handleSelectPage = useCallback(
    pageSelected => {
      setPage(pageSelected);
      history.push(`/servicos/${pageSelected}`);
    },
    [history]
  );

  return (
    <Content>
      <h1>Serviços</h1>
      <Search>
        <FaSearch size={24} color="#FFCA18" />
        <input
          ref={inputRef}
          name="search"
          placeholder="Pesquisar"
          onChange={e => setSearch(e.target.value)}
        />
      </Search>
      {filters.length > 0 && (
        <Filter>
          {filters.map((filter, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              {filter && (
                <div>
                  <p>{filter}</p>
                  <button type="button" onClick={() => removeFilter(index)}>
                    <FaTimes size={20} color="#909090" />
                  </button>
                </div>
              )}
            </Fragment>
          ))}
        </Filter>
      )}
      <Options>
        <ul>
          <li
            className={`${
              page === '' || page.match(/categorias\/.*/) ? 'active' : ''
            }`}
          >
            <button type="button" onClick={() => handleSelectPage('')}>
              Serviços
            </button>
          </li>
          <li className={`${page === 'categorias' ? 'active' : ''}`}>
            <button
              type="button"
              onClick={() => handleSelectPage('categorias')}
            >
              Categorias
            </button>
          </li>
          <li className={`${page === 'destaques' ? 'active' : ''}`}>
            <button type="button" onClick={() => handleSelectPage('destaques')}>
              Destaques
            </button>
          </li>
        </ul>
      </Options>
      <Option>
        {page === '' || page.match(/categorias\/.*/) ? (
          <ServicosList search={search} category={category} />
        ) : (
          <>
            {page === 'categorias' ? (
              <Categorias search={search} />
            ) : (
              <Destaques search={search} />
            )}
          </>
        )}
      </Option>
    </Content>
  );
}
