import React, { useState, useCallback, useEffect } from 'react';
import SelectComponent from 'react-select';
import PropTypes from 'prop-types';

import Input from '~/components/Input';

export default function Select({
  name,
  options,
  placeholder,
  disabled,
  selected,
  handleSelect,
  className,
}) {
  const [valueSelected, setValueSelected] = useState({});
  const [value, setValue] = useState('');

  useEffect(() => {
    setValueSelected(selected);
    setValue(selected);
  }, [options, selected]);

  const handleChange = useCallback(
    data => {
      setValueSelected(data);
      setValue(data.value);
      handleSelect(data.value);
    },
    [handleSelect]
  );

  return (
    <>
      {Object.keys(valueSelected).length > 0 ? (
        <>
          <SelectComponent
            placeholder={placeholder}
            options={options}
            value={valueSelected}
            onChange={handleChange}
            isDisabled={disabled}
            className={className}
            noOptionsMessage={() => 'Não encontrado'}
          />
          <Input type="hidden" name={name} value={value} />
          {/* <InputComponent type="hidden" name={name} value={value} /> */}
        </>
      ) : (
        <>
          <SelectComponent
            placeholder={placeholder}
            options={options}
            onChange={handleChange}
            isDisabled={disabled}
            className={className}
            noOptionsMessage={() => 'Não encontrado'}
          />
          <Input type="hidden" name={name} />
        </>
      )}
    </>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  selected: PropTypes.object,
  handleSelect: PropTypes.func,
  className: PropTypes.string,
};

Select.defaultProps = {
  placeholder: 'Selecione',
  disabled: false,
  selected: {},
  handleSelect: () => {},
  className: '',
};
