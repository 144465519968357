import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import {
  servicesSuccessByCategoria,
  serviceSuccess,
  serviceFailure,
} from './actions';

export function* registerService({ payload }) {
  try {
    const {
      title,
      descricao,
      endereco,
      horaInicio,
      horaFim,
      user_id,
      files,
    } = payload;

    const response = yield call(api.post, `/servicos`, {
      titulo: title,
      descricao,
      local: endereco,
      horario_inicio: horaInicio,
      horario_fim: horaFim,
      created_at: '2020-04-13 20:09:16',
      updated_at: '2020-04-13 20:09:16',
      user_id,
    });

    for (let i = 0; i < files.length; i++) {
      const file = {
        ...files[i],
        servico_id: response.data.id,
        created_at: '2020-04-13 20:09:16',
        updated_at: '2020-04-13 20:09:16',
      };

      yield call(api.post, `/files`, file);
    }

    history.push('/servicos');
  } catch (error) {
    toast.error('Falha na verificação verifique seus dados');
  }
}

export function* serviceRequest({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `/servicos/${id}`);
    const servico = { ...response.data };

    yield put(serviceSuccess(servico));

    history.push('/servico');
  } catch (error) {
    toast.error('Falha no cadastro verifique seus dados!');
    yield put(serviceFailure());
  }
}

export function* servicesRequestByCategoria({ payload }) {
  try {
    const { id } = payload;

    yield put(servicesSuccessByCategoria(id));

    history.push('/servicos/categoria');
  } catch (error) {
    toast.error('Falha no cadastro verifique seus dados!');
    yield put(serviceFailure());
  }
}

export default all([
  takeLatest('@service/REGISTER_SERVICE_REQUEST', registerService),
  takeLatest('@service/SERVICE_REQUEST', serviceRequest),
  takeLatest(
    '@service/SERVICES_REQUEST_BY_CATEGORIA',
    servicesRequestByCategoria
  ),
]);
