import React, { useEffect, useState, useCallback } from 'react';
import { IoMdSend } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import api from '~/services/api';
import history from '~/services/history';

import logo from '~/assets/logos/logo.svg';

import {
  Container,
  Content,
  ServicoImages,
  ImagemPrincial,
  ImgPrincipal,
  ImagesGroup,
  Imgs,
  Images,
  ImageButton,
  ButtonsGroup,
  ServicoData,
  ComentariosGroup,
  Comentarios,
  Comentario,
  Form,
} from './styles';
import Input from '~/components/Input';

export default function Servico() {
  const user = useSelector(state => state.auth.user);
  const params = useParams();
  const [service, setService] = useState({});
  const [imageSelectedUrl, setImageSelectedUrl] = useState('');
  const [imageSelectedIndex, setImageSelectedIndex] = useState(0);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const { slug } = params;
    api.get(`services/${slug}`).then(response => {
      setService(response.data);
      setComments(response.data.comments);
      setImageSelectedUrl(response.data.files[0].url);
    });
  }, [params]);

  const handleSendMessage = useCallback(async () => {
    const response = await api.post('talks', {
      id_user_one: user.id,
      id_user_two: service.id_user,
    });
    if (response.data) {
      const name = service.user.name.toLowerCase().replace(' ', '-');
      history.push(`/chat/${name}-${user.id}-${service.id_user}`);
    }
  }, [service, user]);

  const handleRequestProposal = useCallback(async () => {
    const response = await api.post('talks', {
      id_user_one: user.id,
      id_user_two: service.id_user,
    });
    if (response.data) {
      const message = `Gostaria de receber uma proposta para o seu serviço: ${service.name}`;
      const responseChat = await api.post('chat', {
        message,
        from: user.id,
        to: service.id_user,
        conversaId: response.data.id,
      });

      if (responseChat.data) {
        Swal.fire(
          'Solicitação enviada!',
          'A solicitação de orçamento foi enviada, verifique o seu chat.',
          'success'
        );
      }
    }
  }, [service, user]);

  const handleSelectImage = useCallback((url, index) => {
    setImageSelectedUrl(url);
    setImageSelectedIndex(index);
  }, []);

  const handleSubmitComment = useCallback(
    async ({ comment }, { resetForm }) => {
      const response = await api.post('comments', {
        id_user: user.id,
        id_service: service.id,
        comment,
      });
      if (response.data) {
        const newComment = {
          id: response.data.id,
          user: { name: user.name, avatar: { url: user.avatar.url } },
          comment: response.data.comment,
        };
        setComments(state => [...state, newComment]);
        resetForm();
      }
    },
    [service.id, user.avatar, user.id, user.name]
  );

  return (
    <Container>
      {Object.keys(service).length > 0 ? (
        <>
          <h1>{service.name}</h1>
          <Content>
            <ServicoImages>
              <ImagemPrincial>
                <ImgPrincipal src={imageSelectedUrl} />
                <ImagesGroup mobile>
                  <Images>
                    {service.files.map((file, index) => (
                      <ImageButton
                        key={file.id}
                        onClick={() => handleSelectImage(file.url, index)}
                      >
                        <Imgs
                          src={file.url}
                          selected={index === imageSelectedIndex}
                        />
                      </ImageButton>
                    ))}
                  </Images>
                  {user.id !== service.id_user && (
                    <ButtonsGroup>
                      <button type="button" onClick={handleRequestProposal}>
                        Pedir proposta
                      </button>
                      <button
                        type="button"
                        className="btn-tranparent"
                        onClick={handleSendMessage}
                      >
                        Enviar mensagem
                      </button>
                    </ButtonsGroup>
                  )}
                </ImagesGroup>
                <ServicoData>
                  <p>{service.description}</p>
                  <div>
                    <div>
                      <strong>Prestador:</strong>
                      <p>{service.user.name}</p>
                    </div>
                    <div>
                      <strong>Disponibilidade:</strong>
                      <p>
                        {service.initial_hour} - {service.end_hour}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div>
                      <strong>Contato:</strong>
                      <a href={`tel:${service.user.telphone}`}>
                        {service.user.telphone}
                      </a>
                      <a href={`tel:${service.user.celphone}`}>
                        {service.user.celphone}
                      </a>
                      <a href={`mailto:${service.user.email}`}>
                        {service.user.email}
                      </a>
                    </div>
                    <div>
                      <strong>Endereço:</strong>
                      <p>
                        {service.adress.street}, número {service.adress.number},{' '}
                        {service.adress.neighborhood}, {service.adress.city} -
                        {service.adress.state}, CEP: {service.adress.cep}
                      </p>
                    </div>
                  </div>
                </ServicoData>
              </ImagemPrincial>
              <ImagesGroup desktop>
                <Images>
                  {service.files.map((file, index) => (
                    <ImageButton
                      key={file.id}
                      onClick={() => handleSelectImage(file.url, index)}
                    >
                      <Imgs
                        src={file.url}
                        selected={index === imageSelectedIndex}
                      />
                    </ImageButton>
                  ))}
                </Images>
                {user.id !== service.id_user && (
                  <ButtonsGroup>
                    <button type="button" onClick={handleRequestProposal}>
                      Pedir proposta
                    </button>
                    <button
                      type="button"
                      className="btn-tranparent"
                      onClick={handleSendMessage}
                    >
                      Enviar mensagem
                    </button>
                  </ButtonsGroup>
                )}
              </ImagesGroup>
            </ServicoImages>
            <ComentariosGroup>
              <h2>Comentarios:</h2>
              <Comentarios>
                {comments.length > 0 ? (
                  <>
                    {comments.map(comment => (
                      <Comentario>
                        <img
                          src={comment.user.avatar.url}
                          alt={comment.user.name}
                        />
                        <div>
                          <h3>{comment.user.name}</h3>
                          <p>{comment.comment}</p>
                        </div>
                      </Comentario>
                    ))}
                  </>
                ) : (
                  <Comentario>
                    <img src={logo} alt="Equipe Wodo" />
                    <div>
                      <h3>Equipe Wodo</h3>
                      <p>
                        Nenhum comentário no momento, seja o primeiro a
                        comentar!
                      </p>
                    </div>
                  </Comentario>
                )}
              </Comentarios>
              <Form onSubmit={handleSubmitComment}>
                <Input name="comment" multiline placeholder="Comentar" />
                <button type="submit">
                  <IoMdSend size={20} color="#fff" />
                </button>
              </Form>
            </ComentariosGroup>
          </Content>
        </>
      ) : (
        <p>Caregando...</p>
      )}
    </Container>
  );
}
