import produce from 'immer';

const INITIAL_STATE = {
  service: null,
  id_categoria: null,
};

export default function servico(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@service/REGISTER_SERVICE_SUCCESS': {
        draft.service = action.payload.service;
        break;
      }

      case '@service/SERVICE_SUCCESS_BY_CATEGORIA': {
        draft.id_categoria = action.payload.id_categoria;
        break;
      }

      case '@service/SERVICE_SUCCESS': {
        draft.service = action.payload.service;
        break;
      }

      case '@service/SERVICE_FAILURE': {
        break;
      }

      default:
    }
  });
}
