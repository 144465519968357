import styled from 'styled-components';
import { lighten } from 'polished';

export const Content = styled.div`
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  padding: 0 10px 10px;

  h2 {
    padding: 15px 25px 0;
    font-size: 3.5rem;
    font-weight: 500;
    color: #1d2433;
  }

  @media screen and (max-width: 991px) {
    h2 {
      text-align: center;
      font-size: 3rem;
    }
  }
`;

export const DestaquesList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Destaque = styled.div`
  padding: 20px;
  width: 25%;

  > a {
    display: block;
    border-radius: 17px;
    box-shadow: 0px 2.5px 4px 0px #aaa;
    transition-duration: 0.3s;

    img {
      border-radius: 17px 17px 0px 0px;
      width: 100%;
      max-height: 198px;
    }

    div {
      padding: 10px;
      text-align: center;

      h3 {
        color: #8c8888;
        font-weight: 600;
        font-size: 1.8rem;
      }

      p {
        color: #c5ccd6;
        font-weight: 500;
        font-size: 1.3rem;
      }

      a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #173e4f;
        border: 1px solid #173e4f;
        color: #fff;
        border-radius: 50px;
        height: 30px;
        transition-duration: 0.3s;
        margin-top: 10px;

        :hover {
          border-color: ${lighten(0.1, '#173E4F')};
          background-color: ${lighten(0.1, '#173E4F')};
        }
      }
    }

    :hover {
      box-shadow: 0px 4px 4px 0px #555;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 10px;
    width: 100%;
  }
`;

export const NotFound = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    padding: 0 10px;
    font-size: 2rem;
    color: #707070;
    text-align: center;
  }
`;
