import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import {
  signInRequest,
  signInSuccess,
  updateSuccess,
  signFailure,
} from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;
    const response = yield call(api.post, 'session', {
      email,
      password,
    });

    const { user, token } = response.data;

    api.defaults.headers.Authorization = `Barear ${token}`;

    yield put(signInSuccess(user, token));

    history.push('/');
  } catch (error) {
    toast.error('Falha na verificação verifique seus dados');
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const {
      avatar,
      name,
      email,
      password,
      person_type,
      birthday,
      sex,
      rg,
      document,
      company_name,
      celphone,
      telphone,
      street,
      number,
      neighborhood,
      city,
      state,
      cep,
      complement,
      id_category,
    } = payload;

    const responseAvatar = yield call(api.post, 'avatar', avatar);
    if (responseAvatar) {
      const id_avatar = responseAvatar.data.id;
      const [day, month, year] = birthday.split('/');
      const responseUser = yield call(api.post, 'users', {
        name,
        email,
        password,
        person_type,
        birthday: `${year}-${month}-${day}`,
        sex,
        rg,
        document,
        company_name,
        celphone,
        telphone,
        slug: `${name
          .replace(/\s+/g, '-')
          .toLowerCase()}-${id_category}-${id_avatar}`,
        id_avatar,
        id_category,
        only_client: id_category !== 0 ? 0 : 1,
      });
      if (responseUser) {
        console.log(cep);
        const id_user = responseUser.data.id;
        const responseAdress = yield call(api.post, 'adresses', {
          id_user,
          street,
          number,
          neighborhood,
          city,
          state: state.value,
          cep,
          complement,
          is_main: 1,
        });
        if (responseAdress) {
          yield put(
            signInRequest(responseUser.data.email, responseUser.data.password)
          );
        }
      }
    }
  } catch (error) {
    if (error.response.status === 400) {
      toast.error(error.response.data.error);
    } else {
      toast.error('Falha no cadastro verifique seus dados!');
    }
    yield put(signFailure());
  }
}

export function* update({ payload }) {
  try {
    const {
      id,
      avatar,
      name,
      email,
      person_type,
      birthday,
      sex,
      rg,
      document,
      company_name,
      celphone,
      telphone,
      id_category,
    } = payload;

    let responseAvatar;
    if (typeof avatar !== 'number') {
      responseAvatar = yield call(api.post, 'avatar', avatar);
    } else {
      responseAvatar = {
        data: {
          id: avatar,
        },
      };
    }
    if (responseAvatar) {
      const id_avatar = responseAvatar.data.id;
      const response = yield call(api.put, `users/${id}`, {
        name,
        email,
        person_type,
        birthday,
        sex,
        rg,
        document,
        company_name,
        celphone,
        telphone,
        id_avatar,
        id_category,
        only_client: id_category !== 0 ? 0 : 1,
      });
      if (response) {
        toast.success('Avatar alterado com sucesso!');
        yield put(updateSuccess(response.data));
      }
    }
  } catch (error) {
    toast.error('Falha no cadastro verifique seus dados!');
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Barear ${token}`;
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/UPDATE_REQUEST', update),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
