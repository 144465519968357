/* eslint-disable radix */
import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import api from '~/services/api';

import { Content, FormGroup, Inputs, ButtonGroup } from './styles';
import AvatarGroup from '~/components/AvatarGroup';
import Adresses from '~/components/Adresses';

function MeusDados() {
  const id = useSelector(state => state.auth.user.id);
  const [editable, setEditable] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [isMain, setIsMain] = useState(0);

  useEffect(() => {
    api.get(`adresses/user/${id}`).then(response => {
      response.data.forEach(adress => {
        if (adress.is_main === '1') {
          setIsMain(adress.id);
        }
      });
      setAddresses(response.data);
    });
  }, [id]);

  useEffect(() => {
    if (isMain) {
      addresses.forEach(adress => {
        if (adress.id === parseInt(isMain)) {
          api.patch(`/adresses/${adress.id}`, {
            is_main: '1',
          });
        } else {
          api.patch(`/adresses/${adress.id}`, {
            is_main: '0',
          });
        }
      });
    }
  }, [addresses, isMain]);

  const handleChangeAdress = useCallback((index, adress) => {
    setAddresses(state => {
      state[index] = adress;
      return state;
    });
  }, []);

  const handleSubmit = useCallback(() => {
    try {
      addresses.forEach(adress => {
        api.put(`/adresses/${adress.id}`, {
          id_user: id,
          street: adress.street,
          number: adress.number,
          neighborhood: adress.neighborhood,
          city: adress.city,
          state: adress.state,
          cep: adress.cep,
          complement: adress.complement,
        });
      });
      Swal.fire(
        'Endereços atualizadas!',
        'Seus endereços foram alterada com sucesso.',
        'success'
      );
    } catch (e) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro inexperado tente novamente.',
        'error'
      );
    }
  }, [addresses, id]);

  return (
    <Content>
      <AvatarGroup />
      <FormGroup>
        <h2>Meus Endereços</h2>
        <Form onSubmit={handleSubmit}>
          <Inputs>
            {addresses.map((address, index) => (
              <Adresses
                key={address.id}
                data={address}
                index={index}
                editable={editable}
                setEditable={setEditable}
                handleChangeAdress={handleChangeAdress}
                isMain={isMain}
                setIsMain={setIsMain}
              />
            ))}
          </Inputs>
          <ButtonGroup>
            <button
              type="submit"
              className={`${editable ? 'active' : ''}`}
              onClick={() => setEditable(false)}
            >
              Salvar
            </button>
          </ButtonGroup>
        </Form>
      </FormGroup>
    </Content>
  );
}

export default MeusDados;
