import styled from 'styled-components';
import { shade } from 'polished';

export const Content = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 991px) {
    display: block;
  }
`;

export const FormGroup = styled.div`
  width: 75%;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  padding: 0 10px;

  h2 {
    padding: 0 10px;
    margin: 20px 0;
    font-size: 3.5rem;
    font-weight: 500;
    color: #1d2433;
  }

  form {
    > div {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      > button {
        margin: 0 10px;
        padding: 0 10px;
        width: 150px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ffca18;
        border-radius: 50px;
        color: #ffca18;
        margin-bottom: 15px;
        background: none;
        opacity: 0;
        visibility: hidden;
        transition-duration: 0.3s;

        :hover {
          color: ${shade(0.2, '#FFCA18')};
          border-color: ${shade(0.2, '#FFCA18')};
        }
      }

      > button.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 10px;

    h2 {
      text-align: center;
      font-size: 3rem;
      margin: 0;
    }

    form {
      > div {
        display: block;

        > button {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
`;

export const InputsGroup = styled.div`
  padding: 0 10px;
  width: ${props => (props.w100 ? '100%' : '50%')};

  label {
    font-size: 1.5rem;
    color: #c5ccd6;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;

    > div {
      width: 100%;
    }

    > input {
      width: 100%;
      border: none;
      height: 40px;
      font-size: 1.5rem;
      color: #323643;
      padding: 0 10px;
    }

    input:disabled {
      background-color: #f2f2f2;
      color: #999;
    }

    input.active {
      border-bottom: 1px solid #323643;
    }

    button {
      background: none;
      border: none;
      color: #ffca18;
      margin: 0 10px;
      padding: 0 10px;
      transition-duration: 0.3s;
      opacity: 1;
      visibility: visible;

      :hover {
        color: ${shade(0.2, '#FFCA18')};
      }
    }

    button.active {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 0;

    > div {
      > button {
        width: auto;
        margin: 0;
      }
    }
  }
`;
