import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import api from '~/services/api';

import {
  Container,
  HeaderPerfil,
  Content,
  Status,
  Servicos,
  Servico,
  Img,
} from './styles';

export default function PerfilUsuario() {
  const location = useLocation();
  const [user, setUser] = useState({});

  useEffect(() => {
    const [, , slug] = location.pathname.split('/');
    api.get(`users/${slug}`).then(response => {
      if (response.data) {
        const dataLoadProvider = {
          on_time_antes_prazo: 0,
          on_time_no_prazo: 0,
          on_time_depois_prazo: 0,
          communication_educado: 0,
          communication_essencial: 0,
          communication_grosseiro: 0,
          note: 0,
        };

        const dataProvider = {
          provider_reviews: response.data.providerReviews.length,
          on_time: '',
          communication: '',
          note: 0,
        };

        response.data.providerReviews.map(review => {
          switch (review.on_time) {
            case 'Antes do prazo.':
              dataLoadProvider.on_time_antes_prazo += 1;
              break;
            case 'No prazo.':
              dataLoadProvider.on_time_no_prazo += 1;
              break;
            case 'Depois o prazo.':
              dataLoadProvider.on_time_no_prazo += 1;
              break;
            default:
          }

          switch (review.communication) {
            case 'É educado(a).':
              dataLoadProvider.communication_educado += 1;
              break;
            case 'Fala somente o essencial.':
              dataLoadProvider.communication_essencial += 1;
              break;
            case 'É grosseiro(a).':
              dataLoadProvider.communication_grosseiro += 1;
              break;
            default:
          }

          dataLoadProvider.note += review.note;

          return 0;
        });

        if (
          dataLoadProvider.on_time_antes_prazo >
            dataLoadProvider.on_time_no_prazo &&
          dataLoadProvider.on_time_antes_prazo >
            dataLoadProvider.on_time_depois_prazo
        ) {
          dataProvider.on_time = 'Antes do prazo.';
        } else if (
          dataLoadProvider.on_time_no_prazo >
            dataLoadProvider.on_time_antes_prazo &&
          dataLoadProvider.on_time_no_prazo >
            dataLoadProvider.on_time_depois_prazo
        ) {
          dataProvider.on_time = 'No prazo.';
        } else if (
          dataLoadProvider.on_time_depois_prazo >
            dataLoadProvider.on_time_no_prazo &&
          dataLoadProvider.on_time_depois_prazo >
            dataLoadProvider.on_time_antes_prazo
        ) {
          dataProvider.on_time = 'Depois o prazo.';
        }

        if (
          dataLoadProvider.communication_educado >
            dataLoadProvider.communication_essencial &&
          dataLoadProvider.communication_educado >
            dataLoadProvider.communication_grosseiro
        ) {
          dataProvider.communication = 'É educado(a).';
        } else if (
          dataLoadProvider.communication_essencial >
            dataLoadProvider.communication_educado &&
          dataLoadProvider.communication_essencial >
            dataLoadProvider.communication_grosseiro
        ) {
          dataProvider.communication = 'Fala somente o essencial.';
        } else if (
          dataLoadProvider.communication_grosseiro >
            dataLoadProvider.communication_essencial &&
          dataLoadProvider.communication_grosseiro >
            dataLoadProvider.communication_educado
        ) {
          dataProvider.communication = 'É grosseiro(a).';
        }

        dataProvider.note =
          dataLoadProvider.note / dataProvider.provider_reviews;

        const dataLoadClient = {
          provider_reviews: response.data.providerReviews.length,
          client_reviews: response.data.clientReviews.length,
          payment_antes_prazo: 0,
          payment_no_prazo: 0,
          payment_depois_prazo: 0,
          payment_nao_pago: 0,
          communication_educado: 0,
          communication_essencial: 0,
          communication_grosseiro: 0,
          note: 0,
        };

        const dataClient = {
          client_reviews: response.data.clientReviews.length,
          payment: '',
          communication: '',
          note: 0,
        };

        response.data.clientReviews.map(review => {
          switch (review.payment) {
            case 'Antes do prazo.':
              dataLoadClient.payment_antes_prazo += 1;
              break;
            case 'No prazo.':
              dataLoadClient.payment_no_prazo += 1;
              break;
            case 'Depois o prazo.':
              dataLoadClient.payment_no_prazo += 1;
              break;
            case 'Não pago.':
              dataLoadClient.payment_nao_pago += 1;
              break;
            default:
          }

          switch (review.communication) {
            case 'É educado(a).':
              dataLoadClient.communication_educado += 1;
              break;
            case 'Fala somente o essencial.':
              dataLoadClient.communication_essencial += 1;
              break;
            case 'É grosseiro(a).':
              dataLoadClient.communication_grosseiro += 1;
              break;
            default:
          }

          dataLoadClient.note += review.note;

          return 0;
        });

        if (
          dataLoadClient.payment_antes_prazo >
            dataLoadClient.payment_no_prazo &&
          dataLoadClient.payment_antes_prazo >
            dataLoadClient.payment_depois_prazo &&
          dataLoadClient.payment_antes_prazo > dataLoadClient.payment_nao_pago
        ) {
          dataClient.payment = 'Antes do prazo.';
        } else if (
          dataLoadClient.payment_no_prazo >
            dataLoadClient.payment_antes_prazo &&
          dataLoadClient.payment_no_prazo >
            dataLoadClient.payment_depois_prazo &&
          dataLoadClient.payment_no_prazo > dataLoadClient.payment_nao_pago
        ) {
          dataClient.payment = 'No prazo.';
        } else if (
          dataLoadClient.payment_depois_prazo >
            dataLoadClient.payment_no_prazo &&
          dataLoadClient.payment_depois_prazo >
            dataLoadClient.payment_antes_prazo &&
          dataLoadClient.payment_depois_prazo > dataLoadClient.payment_nao_pago
        ) {
          dataClient.payment = 'Depois o prazo.';
        } else if (
          dataLoadClient.payment_nao_pago > dataLoadClient.payment_no_prazo &&
          dataLoadClient.payment_nao_pago >
            dataLoadClient.payment_antes_prazo &&
          dataLoadClient.payment_nao_pago > dataLoadClient.payment_depois_prazo
        ) {
          dataClient.payment = 'Não pago.';
        }

        if (
          dataLoadClient.communication_educado >
            dataLoadClient.communication_essencial &&
          dataLoadClient.communication_educado >
            dataLoadClient.communication_grosseiro
        ) {
          dataClient.communication = 'É educado(a).';
        } else if (
          dataLoadClient.communication_essencial >
            dataLoadClient.communication_educado &&
          dataLoadClient.communication_essencial >
            dataLoadClient.communication_grosseiro
        ) {
          dataClient.communication = 'Fala somente o essencial.';
        } else if (
          dataLoadClient.communication_grosseiro >
            dataLoadClient.communication_essencial &&
          dataLoadClient.communication_grosseiro >
            dataLoadClient.communication_educado
        ) {
          dataClient.communication = 'É grosseiro(a).';
        }

        dataClient.note = dataLoadClient.note / dataClient.client_reviews;

        const data = {
          id: response.data.id,
          name: response.data.name,
          avatar: response.data.avatar.url,
          category: response.data.category,
          providerReviews: dataProvider,
          clientReviews: dataClient,
          services: response.data.services,
          existService: response.data.services.length > 0,
        };

        setUser(data);
      }
    });
  }, [location]);

  return (
    <Container>
      {Object.keys(user).length > 0 ? (
        <>
          <HeaderPerfil>
            <div>
              <img src={user.avatar} alt={user.name} />
            </div>
            <div>
              <h1>{user.name}</h1>
              <p>{user.category && user.category.name}</p>
            </div>
          </HeaderPerfil>
          <Content>
            <Status>
              <div>
                <h2>Como Prestador(a)</h2>
                {user.providerReviews.provider_reviews > 0 ? (
                  <>
                    <p>
                      Teve {user.providerReviews.provider_reviews}{' '}
                      {user.providerReviews.provider_reviews === 1
                        ? 'avaliação'
                        : 'avaliações'}
                      .
                    </p>
                    <h3>Normalmente os serviços são entregue:</h3>
                    <p>{user.providerReviews.on_time}</p>
                    <h3>Normalmente nas comunicações ele:</h3>
                    <p>{user.providerReviews.communication}</p>
                    <h3>Sua nota como prestador é:</h3>
                    <p>{user.providerReviews.note}/5</p>
                  </>
                ) : (
                  <p>Ainda não teve avaliação.</p>
                )}
              </div>
            </Status>
            <Status>
              <div>
                <h2>Como Cliente</h2>
                {user.clientReviews.client_reviews > 0 ? (
                  <>
                    <p>
                      Teve {user.clientReviews.client_reviews}{' '}
                      {user.clientReviews.client_reviews === 1
                        ? 'avaliação'
                        : 'avaliações'}
                      .
                    </p>
                    <h3>Normalmente os pagamentos são realizados:</h3>
                    <p>{user.clientReviews.payment}</p>
                    <h3>Normalmente nas comunicação ele:</h3>
                    <p>{user.clientReviews.communication}</p>
                    <h3>Sua nota como cliente é:</h3>
                    <p>{user.clientReviews.note}/5</p>
                  </>
                ) : (
                  <p>Ainda não teve avaliação.</p>
                )}
              </div>
            </Status>
            {user.existService && (
              <Servicos>
                <h2>Serviços</h2>
                <div>
                  {user.services.map(service => (
                    <Servico key={service.id}>
                      <div>
                        <Img src={service.files[0].url} />
                        <div>
                          <h3>{service.name}</h3>
                          <p>{service.description}</p>
                          <Link to={`/servico/${service.slug}`}>
                            Visualizar
                          </Link>
                        </div>
                      </div>
                    </Servico>
                  ))}
                </div>
              </Servicos>
            )}
          </Content>
        </>
      ) : (
        <p>Caregando...</p>
      )}
    </Container>
  );
}
