import styled from 'styled-components';
import { lighten } from 'polished';

export const Content = styled.div`
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  padding: 0 10px 10px;

  h2 {
    padding: 15px 25px 0;
    font-size: 3.5rem;
    font-weight: 500;
    color: #1d2433;
  }
`;

export const ServicosList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Servico = styled.div`
  padding: 20px;
  width: 25%;

  > div {
    border-radius: 17px;
    box-shadow: 0px 2.5px 4px 0px #aaa;

    div {
      padding: 10px;

      h3 {
        color: #8c8888;
        font-weight: 600;
        font-size: 1.8rem;
      }

      p {
        color: #c5ccd6;
        font-weight: 500;
        font-size: 1.3rem;
      }

      a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #173e4f;
        border: 1px solid #173e4f;
        color: #fff;
        border-radius: 50px;
        height: 30px;
        transition-duration: 0.3s;
        margin-top: 10px;

        :hover {
          border-color: ${lighten(0.1, '#173E4F')};
          background-color: ${lighten(0.1, '#173E4F')};
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 10px;

    h3 {
      text-align: center;
      font-size: 3rem;
    }
  }
`;

export const Img = styled.div`
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 17px 17px 0px 0px;
  width: 100%;
  height: 198px;
`;

export const Vazio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  width: 100%;

  strong {
    font-size: 3rem;
    color: #ccc;
    text-align: center;
  }
`;
