import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropsTypes from 'prop-types';

import Header from '~/components/Header';
// import Footer from '~/components/Footer';

import { Wrapper, HeaderSpacer, Separator } from './styles';

export default function DefaultLayout({ children }) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Wrapper>
      <HeaderSpacer>
        <Header />
      </HeaderSpacer>
      <Separator>
        {children}
        {/* <Footer /> */}
      </Separator>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropsTypes.element.isRequired,
};
