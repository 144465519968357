import styled from 'styled-components';
import { lighten } from 'polished';
import CarouselComponent from 'react-multi-carousel';

export const LogoMobile = styled.div`
  @media screen and (min-width: 992px) {
    display: none;
  }

  background: #fff;
  box-shadow: 0px 0px 5px 2px #00000033;
  width: calc(100% + 40px);
  margin: -20px 0 10px -20px;
  padding: 5px;
  text-align: center;
`;

export const Carousel = styled(CarouselComponent)`
  li {
    border-radius: 20px;

    :nth-child(1) {
      background: #aed3fa !important;
    }

    :nth-child(2) {
      background: #7b9db7 !important;
    }

    :nth-child(3) {
      background: #5c809a !important;
    }

    :nth-child(4) {
      background: #0a2132 !important;
    }
  }

  .react-multiple-carousel__arrow {
    background: transparent;
  }
`;

export const Content = styled.div`
  height: 350px;
`;

export const Destaques = styled.div`
  width: 100%;
  padding: 40px 0;

  h2 {
    color: #bcbcbc;
    font-size: 3rem;
    margin-bottom: 30px;
  }

  > p {
    width: 40%;
    color: #868e99;
    font-size: 1.5em;
    text-align: center;
    margin: 15px auto;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 991px) {
    padding: 10px 0;

    h2 {
      font-size: 2.5rem;
      text-align: center;
      margin-bottom: 0;
    }

    > p {
      width: 100%;
      font-size: 1.5em;
      margin: 15px auto;
    }
  }
`;

export const Destaque = styled.div`
  padding: 20px;
  width: 25%;

  > a {
    display: block;
    border-radius: 17px;
    box-shadow: 0px 2.5px 4px 0px #aaa;
    transition-duration: 0.3s;

    img {
      border-radius: 17px 17px 0px 0px;
      width: 100%;
      max-height: 198px;
    }

    div {
      padding: 10px;
      text-align: center;

      h3 {
        color: #8c8888;
        font-weight: 600;
        font-size: 1.8rem;
      }

      p {
        color: #c5ccd6;
        font-weight: 500;
        font-size: 1.3rem;
      }

      a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #173e4f;
        border: 1px solid #173e4f;
        color: #fff;
        border-radius: 50px;
        height: 30px;
        transition-duration: 0.3s;
        margin-top: 10px;

        :hover {
          border-color: ${lighten(0.1, '#173E4F')};
          background-color: ${lighten(0.1, '#173E4F')};
        }
      }
    }

    :hover {
      box-shadow: 0px 4px 4px 0px #555;
    }
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const BgCategorias = styled.div`
  background: #daecff;
`;

export const Categorias = styled.div`
  width: 100%;
  padding: 40px 0;

  h2 {
    color: #bcbcbc;
    font-size: 3rem;
    margin-bottom: 30px;
  }

  > p {
    width: 40%;
    color: #868e99;
    font-size: 2.1rem;
    text-align: center;
    margin: 15px auto;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 991px) {
    padding: 20px 0;

    h2 {
      font-size: 2.5rem;
      margin-bottom: 0px;
    }

    > p {
      width: 100%;
      font-size: 2.1rem;
      margin: 15px auto;
    }
  }
`;

export const Categoria = styled.div`
  padding: 10px;
  width: 25%;
  height: 150px;

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 17px;
    background-color: #d17777;
    width: 100%;
    height: 100%;
    transition-duration: 0.3s;
    padding: 0 10px;
    text-align: center;

    h3 {
      color: #ffffff;
      font-weight: 600;
      font-size: 2rem;
    }

    :hover {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;
